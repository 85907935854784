import {Dispatch, SetStateAction} from "react";
import {PermissionsType} from "./hooks/hooks";
import {userType} from "../../../types";

export const columnViewEnum = ["text", "thumb", "number", "user", "date", "currency", "file", "checkbox", "singleOption", "multiOption"] as const;

export type ViewType = (typeof columnViewEnum)[number];

export type CategoryNumber = {
    min: number;
    max: number;
};

export type DGColType = {
    hidden?: boolean;
    deleted?: boolean;
    color?: {hex: string};
    /**
     * Options for selectable col type
     */
    options?: string[];
    viewType: ViewType;
    /**
     * Translate id
     */
    label?: string;
    id: string;
    /**
     * Enable sort by this column
     */
    sort?: boolean;
    className?: string;
    /**
     * Can be set as initial hidden column
     */
    isVisible?: boolean;
    // /**
    //  * Columns filters will be different baseed on this value
    //  */
    // filterType?: FilterTypeType;
    /**
     * Disable visibility feature for this column
     */
    noHide?: boolean;
    disableEdit?: boolean;
    minWidth?: number;
    modifiedTime?: number;
};
// & (DGColMinW | DGColFixedW);

// type DGColMinW = {
//     /**
//      * Initial width, can't use with fixedWidth
//      */
//     minWidth?: number;
//     fixedWidth?: never;
// };
// type DGColFixedW = {
//     /**
//      * If there is a fixWidth, column can't be resized
//      */
//     minWidth?: never;
//     fixedWidth?: number;
// };

export type filterType = {
    /**
     * Unique, becaues each col can only have one filter
     */
    colId: string;
} & ConditionToValueType;

type containsType = {
    condition: "contains";
    value: string;
};

type optionType = {
    condition: "option";
    value: string[];
};

type eqType = {
    condition: "eq";
    value: string | string[] | any[];
};

type betweenType = {
    condition: "between";
    value: {from: string; to: string};
};

type emptyType = {
    condition: "empty" | "notEmpty";
    value?: undefined;
};

type neqType = {
    condition: "neq" | "lt" | "startsWith" | "endsWith" | "gt" | "eq";
    value: string;
};

type dateType = {
    condition: "same" | "before" | "after" | "range";
    value: {dateFrom: number; dateTo?: number};
};

export type ConditionToValueType = containsType | eqType | betweenType | neqType | emptyType | dateType | optionType;

export type DGRowType = Record<string, any> & {id: string};

export const colDefaultMinWidth = 80;

// export type DGType = {
//     id: string;
//     activeTime: number;
//     cols: DGColType[];
// };

export type ConflictType<T> = {
    added: T[];
    removed: T[];
    modified: T[];
};

export type DGCellProps = {
    row: DGRowType;
    col: DGColType;
    focusedEdit: boolean;
    setFocusedEdit: Dispatch<SetStateAction<string>>;
    nextFocus?: string;
};

export type RenderEditProps = {
    row: DGRowType;
    initValue?: any;
    onDone: (value: any, shouldGoNext?: boolean) => void;
    onCancel(): void;
    col: DGColType;
    fullView?: boolean;
};

export type DGResType = {
    data?: {
        cols?: DGColType[];
        rows?: DGRowType[];
    };
    timestamp: number;
    activeTime: number;
    title: string;
    permission: PermissionsType;
    user: userType;
};
