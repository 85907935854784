import {Checkbox, Icon} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {EmptyView} from "@ui";
import clsx from "clsx";
import type {ReactNode} from "react";
import { useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useSWR from "swr";
import {ErrorButton} from "../../../../utils/utilComponents";
import Pagination from "../components/Pagination";
import {useFilters} from "../hooks/filtersContext";
import {useMainContext} from "../hooks/mainContext";

type SelectOptions<TItem> = {
    disableSelectForItem?: (item: any) => boolean;
    selectable?: boolean;
    disablePagination?: boolean;
    renderItem?: (item: TItem) => ReactNode;
    itemProps?: (item: TItem) => Record<string, any>;
    renderItemDrawer?: ({item, closeDrawer}: {item: TItem; closeDrawer: () => void}) => ReactNode;
};

export const FilteredListView = <TItem extends {id: string}>({
    disablePagination,
    disableSelectForItem,
    selectable,
    itemProps,
    renderItem,
    renderItemDrawer,
}: SelectOptions<TItem>) => {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(new Set());
    const [selected, setSelected] = useState<TItem>();
    const {link} = useMainContext();
    const {filters, hasFilters, clearFilters} = useFilters();
    const {data, error, isLoading, mutate} = useSWR([link, filters]);
    const content = data && "content" in data ? data.content : data;
    const page = data && "page" in data ? data.page : 1;
    const size = data && "size" in data ? data.size : content?.length || 0;
    const topRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div ref={topRef} className={"absolute top-0"} />
            <div className={"flex items-stretch"}>
                <List dense classes={{root: "py-0 flex-1"}} data-cy={"list-content"}>
                    {content && content.length > 0 && !isLoading ? (
                        content.map((_, i) => {
                            const isChecked = checked ? checked.has(_.id) : false;
                            const itemP = () => {
                                if (itemProps) return itemProps(_);
                                else {
                                    return {};
                                }
                            };
                            return (
                                <ListItem
                                    component={"li"}
                                    divider
                                    classes={{root:clsx("py-0 md:flex-1", selected ? " w-0 overflow-hidden md:w-auto" : "flex-1 flex-wrap md:flex-nowrap")}}
                                    key={i}
                                    selected={selected ? selected.id === _.id : false}
                                    button={itemProps ? itemProps(_).onClick : false}
                                    // divider={!listView}
                                    {...itemP()}
                                    onClick={() => {
                                        topRef?.current?.scrollIntoView?.();
                                        setSelected(_);
                                    }}
                                    className={clsx(
                                        ((itemProps && typeof itemProps(_).onClick === "function") || renderItemDrawer) && "cursor-pointer",
                                        itemProps && itemProps(_).className,
                                        "hover:bg-grey-lightest"
                                    )}>
                                    <Typography className={"-ml-16 pl-4 w-40 text-grey-dark"}>
                                        {size && page ? size * (page - 1) + i + 1 : i + 1}
                                    </Typography>

                                    {selectable && (
                                        <Checkbox
                                            disabled={!!(typeof disableSelectForItem === "function" && disableSelectForItem(_))}
                                            classes={{root: "mr-8"}}
                                            checked={isChecked}
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={() =>
                                                checked.has(_.id)
                                                    ? setChecked((prev) => {
                                                          prev.delete(_.id);
                                                          return prev;
                                                      })
                                                    : setChecked((prev) => new Set(prev).add(_.id))
                                            }
                                        />
                                    )}
                                    {renderItem && renderItem(_)}
                                </ListItem>
                            );
                        })
                    ) : (
                        <EmptyView
                            refresh={mutate}
                            error={error}
                            loading={isLoading}
                            label={
                                hasFilters ? (
                                    <div>
                                        {t("No result for your search")}{" "}
                                        <ErrorButton startIcon={<Icon>close</Icon>} onClick={clearFilters} className={"ml-12 "} variant={"contained"}>
                                            {t("Clear search")}
                                        </ErrorButton>
                                    </div>
                                ) : undefined
                            }
                        />
                    )}
                </List>

                {selected && renderItemDrawer && (
                    <div className={"flex-1  border-l"}>
                        {renderItemDrawer({
                            item: selected,
                            closeDrawer: () => setSelected(undefined),
                        })}
                    </div>
                )}
            </div>
            {content && content.length > 0 && !disablePagination && (
                <div className="flex items-center justify-end border-t" data-cy={"pagination-wrapper"}>
                    <Pagination />
                </div>
            )}
        </>
    );
};
