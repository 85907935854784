import {turnOff, turnOn} from "@fuseActions";
import {useSocket} from "@hooks";
import {Button, Drawer, Icon, List, ListItemIcon, ListSubheader} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {logoutUser} from "app/auth/store/actions";
import Preferences from "components/layout/profile/Preferences.js";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import BasicInfo from "./BasicInfo";
import ProfileEdit from "./ProfileEdit";
import {usePersonalized} from "../../../hooks/usePersonalized";

// const tabTitles = ["", "_.Settings", "profile.general", "profile.theme", "profile.contact", "profile.security"];

type Views = "main" | "preferences" | "editProfile";

const ProfileDrawer = (props) => {
    const {t, i18n} = useTranslation(["profile", "_"]);
    const dispatch = useDispatch() as (action: any) => void;

    const {socket} = useSocket();
    const {updateSettings} = usePersonalized();

    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    const userProfile = useSelector(({fuse}) => fuse.dialogs["userProfile"]);

    const [view, setView] = useState<Views>("main");

    const onLanguage = (code: "en" | "cn") => {
        props.setActiveLanguage(code);
        localStorage.setItem("i18nextLng", code);
        // changeLanguageCode(code);
        updateSettings({language: code});
        i18n.changeLanguage(code);
    };

    const toggleView = () => {
        // console.log(listView);
        if (listView) {
            dispatch(turnOff("listView"));
            updateSettings({listView: "0"});
            localStorage.setItem("listView", "0");
        } else {
            dispatch(turnOn("listView"));
            updateSettings({listView: "1"});
            localStorage.setItem("listView", "1");
        }
    };

    return (
        <Drawer
            transitionDuration={200}
            BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
            classes={{paper: "max-w-full w-512"}}
            open={userProfile}
            anchor="right"
            onClose={() => {
                dispatch(turnOff("userProfile"));
                setView("main");
            }}>
            {view === "main" ? (
                <>
                    <BasicInfo />
                    <List subheader={<ListSubheader>{t("personalization")}</ListSubheader>}>
                        <ListItem>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="language" />
                            </ListItemIcon>
                            <ListItemText primary={<Translate id={"_.Change language"} />} />
                            <div className={""}>
                                <Button
                                    disabled={props.activeLanguage && props.activeLanguage.code === "en"}
                                    size="small"
                                    className={"ml-12"}
                                    onClick={() => onLanguage("en")}
                                    startIcon={
                                        <img
                                            style={{
                                                filter:
                                                    props.activeLanguage && props.activeLanguage.code === "en" ? "grayscale(100%)" : "grayscale(0)",
                                            }}
                                            className={"w-24 h-24 grayscale "}
                                            src={"assets/images/flags/eng.png"}
                                            alt={"English language"}
                                        />
                                    }>
                                    English
                                </Button>

                                <Button
                                    disabled={!(props.activeLanguage && props.activeLanguage.code === "en")}
                                    size="small"
                                    onClick={() => onLanguage("cn")}
                                    startIcon={
                                        <img
                                            style={{
                                                filter: !(props.activeLanguage && props.activeLanguage.code === "en")
                                                    ? "grayscale(100%)"
                                                    : "grayscale(0)",
                                            }}
                                            className={"w-24 h-24"}
                                            src={"assets/images/flags/china.png"}
                                            alt={"Chinese language"}
                                        />
                                    }>
                                    中文
                                </Button>
                            </div>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="view_list" />
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary={<Translate id="_.List View Mode" />} />
                            <ButtonGroup color={"primary"} size={"medium"}>
                                <Button data-cy={"view-table-btn"} variant={listView ? "contained" : "outlined"} onClick={toggleView}>
                                    <Icon children="view_list" />
                                </Button>
                                <Button data-cy={"view-list-btn"} variant={!listView ? "contained" : "outlined"} onClick={toggleView}>
                                    <Icon children="view_compact" />
                                </Button>
                            </ButtonGroup>
                        </ListItem>
                        <ListItem button onClick={() => setView("preferences")}>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="yard" />
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary={<Translate id="profile.theme" />} />
                            <Icon children="chevron_right" />
                        </ListItem>
                        <ListItem button onClick={() => setView("editProfile")}>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="manage_accounts" />
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary={t("Edit profile")} />
                            <Icon children="chevron_right" />
                        </ListItem>
                        <Divider className={"mt-12"} />
                    </List>
                    <List subheader={<ListSubheader>{t("myData")}</ListSubheader>}>
                        <ListItem
                            onClick={() => {
                                dispatch(turnOff("userProfile"));
                                setView("main");
                            }}
                            button
                            component={Link}
                            to={"/datagrids"}>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="view_column" />
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary={t("myDatagrids")} />
                            <Icon children="chevron_right" />
                        </ListItem>
                        <Divider className={"mt-12"} />
                    </List>
                    <List subheader={<ListSubheader>{t("actions")}</ListSubheader>}>
                        {/*<ListItem  button onClick={() => }>*/}
                        {/*    <ListItemIcon className={"min-w-40"}>*/}
                        {/*        <Icon children="padding" />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText className="pl-0" primary={<Translate id="navi.Edit profile" />} />*/}
                        {/*</ListItem>*/}
                        {/*<ListItem component={Link} button to="/profile/settings/general" onClick={() => dispatch(fuseActions.turnOff("userProfile"))}>*/}

                        {/*<ListItem button onClick={() => setTabValue(1)}>*/}
                        {/*    <ListItemIcon className={"min-w-40"}>*/}
                        {/*        <Icon children="local_florist" />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText className="pl-0" primary={<Translate id="profile.theme" />} />*/}
                        {/*    <Icon children="chevron_right" />*/}
                        {/*</ListItem>*/}
                        {/*{masterFirm?.user?.id === profile?.id && (*/}
                        {/*    <MenuItem*/}
                        {/*        data-test={"firm-settings"}*/}
                        {/*        component={Link}*/}
                        {/*        to={"/hr/employees"}*/}
                        {/*        onClick={() => {*/}
                        {/*            dispatch(turnOff("userProfile"));*/}
                        {/*        }}>*/}
                        {/*        <ListItemIcon className={"min-w-40"}>*/}
                        {/*            <Icon children={"work"} />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <Translate>{({translate}) => <ListItemText className="pl-0" primary={translate("navi.Company Settings")} />}</Translate>*/}
                        {/*    </MenuItem>*/}
                        {/*)}*/}
                        <ListItem button onClick={() => dispatch(turnOn("downloadQRCodes"))}>
                            <ListItemIcon className={"min-w-40"}>
                                <Icon children="get_app" />
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary={<Translate id="navi.Download APP" />} />
                        </ListItem>

                        <ListItem
                            onClick={() => {
                                if (socket) {
                                    socket.close();
                                    socket.disconnect();
                                }
                                dispatch(logoutUser());
                                dispatch(turnOff("userProfile"));
                            }}
                            button>
                            <ListItemIcon className={"min-w-40 text-red"}>
                                <Icon children="exit_to_app" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{className: "text-red  font-bold"}} primary={<Translate id="navi.Logout" />} />
                        </ListItem>
                    </List>
                </>
            ) : (
                <div className={"flex items-center border-b "}>
                    <IconButton onClick={() => setView("main")}>
                        <Icon children="arrow_back" />
                    </IconButton>
                    <Typography variant="h5">{t(view === "editProfile" ? "Edit profile" : "theme")}</Typography>
                </div>
            )}

            {view === "editProfile" && <ProfileEdit />}

            {view === "preferences" && (
                <div className="px-16">
                    <Preferences />
                </div>
            )}
        </Drawer>
    );
};

export default withLocalize(ProfileDrawer);
