import {Typography} from "@material-ui/core";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import FuseLoading from "../@fuse/components/FuseLoading/FuseLoading";

class Error404Page extends Component {
    //    componentDidMount = () => {
    //        /*-----------------------------------------------------------*/
    //        // console.info("[Method: componentDidMount ]");
    //        // console.log();
    //        /*-----------------------------------------------------------*/
    //        //this.props.clearError();
    //    };

    render() {
        const {loading} = this.props;
        return loading ? (
            <FuseLoading className={"py-32"} />
        ) : (
            <div className={"flex flex-col flex-1 items-center justify-center p-16"} data-cy={"404-ppage"}>
                <div className="max-w-512 text-center">
                        <Typography variant="h1" color="inherit" className="font-medium mb-16">
                            404
                        </Typography>
                        <Typography variant="h5" color="textSecondary" className="mb-16">
                            <Translate id={"_.Sorry but You don't have access to this resources"} />
                        </Typography>
                        <Link className="font-medium" to="/dashboard">
                            <Translate id={"_.Go back to dashboard"} />
                        </Link>
                </div>
            </div>
        );
    }
}

//function mapDispatchToProps(dispatch) {
//    return bindActionCreators(
//        {
//            clearError: fuseActions.pageErrorClear
//        },
//        dispatch
//    );
//}

function mapStateToProps({fuse}) {
    return {
        loading: fuse.loading,
    };
}

export default withLocalize(withRouter(connect(mapStateToProps)(Error404Page)));
