import {deleteFavorite, favorite} from "@fuseActions";
import {Icon, IconButton} from "@material-ui/core";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import type {ReactNode} from "react";

import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";

type Props = {
    type: string;
    title: string | ReactNode;
    colored?: boolean;
    sourceId: string;
    className?: string;
};

const FaveButton = ({type, title, colored, sourceId, className}: Props) => {
    // constructor(props) {
    //     super(props);
    //     this.props.addTranslation(source);
    // }
    // useEffect(() => {
    //     // @ts-ignore
    //     rest.addTranslation(source);
    // }, []);
    const dispatch = useDispatch();
    // @ts-ignore
    const fav = useSelector(({fuse}) => fuse.favorites);

    const isFave = fav[type] && fav[type].find((_s: {sourceId: string}) => _s.sourceId === sourceId);
    return (
        <MyTooltip
            title={
                <>
                    <Translate id="_.Favorite" /> {title ? <Translate id={`${title}`} /> : sourceId}{" "}
                </>
            }>
            <IconButton
                className={classNames(colored && "text-yellow-dark", "no-drag mx-4 print:hidden ", className)}
                onClick={(e) => {
                    e.stopPropagation();
                    // @ts-ignore
                    !isFave ? dispatch(favorite(type, sourceId, title)) : dispatch(deleteFavorite(isFave.id, type));
                }}>
                <Icon>{isFave === undefined ? "star_border" : "star"}</Icon>
            </IconButton>
        </MyTooltip>
    );
};

// @ts-ignore
export default FaveButton;
