import type {StateCreator} from "zustand";

export type UserpaySlice = {
    item: any;
    onChangeItem: (t: any) => void;
}

export const createUserPaySlice: StateCreator<UserpaySlice> = (set) => ({
    item: null,
    onChangeItem: (t) => {
        set({
            item: t,
        });
    },
});
