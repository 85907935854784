import {setCache, toggleCheck, turnOn} from "@fuseActions";
import type {PaperProps} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import {MyAvatar, MyTooltip} from "@ui";
import classNames from "classnames";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import type {WPListType} from "../../types";
import useWPS from "../../useWPS";

const mapUnreadToClass = {
    0: "",
    1: "",
    2: "bg-red-lightest",
    3: "bg-red-lighter",
};

const WPItem = ({wp, small, className, ...rest}: {small?: boolean; wp: WPListType} & PaperProps) => {
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const checked = useSelector(({fuse}) => fuse.checked);
    const dispatch = useDispatch();
    const {getData} = useWPS();

    // const onSearch = (tag) => (e) => {
    //     e.stopPropagation();
    //     setState({searchText: "#" + tag});
    //     props
    //         .replaceParams({
    //             ...props.match.params,
    //             // size: 10,
    //             keyword: "#" + tag,
    //         })
    //         .then(() => props.getData(props.search));
    // };

    // const refresh = () => {
    //     props.getData(props.search);
    //     // setState({page: 1})
    // };
    //
    // const readWPS = (callback) => {
    //     const {wp} = props;
    //
    //     const data = {workplanIds: [wp.id]};
    //     axios.put(`${firmPrefix}/readWorkplan`, data).then(({data}) => {
    //         props.showMessage({message: "message.Read successfully", variant: "success"});
    //         // clearCheckboxes();
    //         // props.refresh();
    //         props.getData(props.search);
    //         callback();
    //     });
    // };

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return (
    //         nextProps.dialogs["openWPSCheckboxes"] !== props.dialogs["openWPSCheckboxes"] ||
    //         nextProps.dialogs["lastClickedWP"] !== props.dialogs["lastClickedWP"] ||
    //         !_.isEqual(nextProps.wps, props.wps) ||
    //         nextProps.dialogs["WPSViewColumn"] !== props.dialogs["WPSViewColumn"]
    //     );
    // }

    // const {
    //     wp,
    //     dialogs,
    //     small,
    //     square,
    //     turnOn,
    //     elevation,
    //     className,
    //     openUserSidebar,
    //     toggleCheckWP,
    //     wps,
    //     selectWPId,
    //     setCache,
    //     getData,
    //     search,
    // } = props;
    // const {checkedWPS} = wps;

    return (
        <Paper
            data-cy={"wp-listItem"}
            // component={Link} to={`/workplan/${wp.id}`}
            onClick={() => {
                if (!wp.isRead) {
                    getData();
                }
                //     readWPS(()=>{
                //         getWP(wp.id);
                //         if(isElectron()) {
                //             window.ipcRenderer.send( 'openNewWindow', {url: `/electron/wp/${wp.id}`, width: 900, height: 600})
                //         } else turnOn('wpDetail');
                //     });
                // } else {
                dispatch(setCache("lastClickedWP", wp.id));
                dispatch(setCache("selectedWPId", wp.id));
                // dispatch(selectWPId(wp.id));
                // if (isElectron()) {
                //     window.ipcRenderer.send("openNewWindow", {
                //         url: `/electron/wp/${wp.id}`,
                //         width: 900,
                //         height: 600,
                //     });
                // } else
                dispatch(turnOn("wpDetail"));
                // }
            }}
            {...rest}
            className={classNames(
                // !wp.isRead && "bg-red-lighter",
                mapUnreadToClass[wp.unreadLevel],
                dialogs["lastClickedWP"] === wp.id && "bg-grey-light",
                className,
                "cursor-pointer border-b-1s active:bg-grey-light focus:bg-grey-lighter hover:bg-blue-lighter flex-grow flex items-stretch"
            )}>
            <div className="flex flex-grow justify-between items-center">
                <div className={small ? "p-1 nowrap" : "p-2 nowrap"}>
                    {dialogs["openWPSCheckboxes"] && (
                        <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            checked={checked["workplans"] ? checked["workplans"].includes(wp.id) : false}
                            onChange={() => dispatch(toggleCheck(wp.id, "workplans"))}
                            // onChange={() => dispatch(toggleCheckWP(wp.id))}
                            size={"small"}
                        />
                    )}
                    {/*<span className="inline-block mr-2" style={{ width: 0, height: 0, borderLeft: ".8rem solid rgba(28, 61, 90, var(--tw-bg-opacity))",  borderTop: "0.6rem solid transparent", borderBottom: "0.6rem solid transparent"}} />*/}
                    {/*<MyTooltip title={<NameLabel user={wp.user} />}>*/}
                    {/*    <button*/}
                    {/* fix me check the classname if it's right */}
                    {/*        className="p-0 mr-2 align-middle"*/}
                    {/*        onClick={(e) => {*/}
                    {/*            e.stopPropagation();*/}
                    {/*            openUserSidebar(wp.user.id);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {wp.isPin ? <img className="w-20 h-20 mr-2 inline-block" alt="pin" data-cy={"pinned-icon"} src="assets/images/pin.png" /> : ""}
                    <Badge
                        className="p-0 mr-2 align-middle"
                        overlap="rectangular"
                        invisible={wp.isRead}
                        color={"error"}
                        badgeContent={"@"}
                        variant={wp.unreadLevel === 3 ? "standard" : "dot"}>
                        <MyAvatar user={wp.user} size={20} />
                    </Badge>
                    {/*    </button>*/}
                    {/*</MyTooltip>*/}
                    <Typography variant={"button"} className={"text-left capitalize align-middle font-normal "}>
                        {wp.title || wp.description}
                    </Typography>
                    {wp.tag?.trim?.() !== "" &&
                        wp.tag
                            .split(",")
                            .map((t, i) => <Chip color={"default"} key={i} size={"small"} className="ml-2 h-16 cursor-pointer" label={t} />)}
                </div>
                {!small && !dialogs["openWPSCheckboxes"] && dialogs["WPSViewColumn"] > 5 && (
                    <div className={"flex items-center text-xs text-grey-dark"}>
                        {wp.hasLeader && (
                            <MyTooltip title={<Translate id={"wps.Has leader"} />}>
                                <Icon fontSize={"small"} className={"mr-8"} color={"disabled"}>
                                    account_box
                                </Icon>
                            </MyTooltip>
                        )}
                        {wp.isField && (
                            <MyTooltip title={<Translate id={"wps.In Field"} />}>
                                <Icon fontSize={"small"} className={"mr-8"} color={"action"}>
                                    nature_people
                                </Icon>
                            </MyTooltip>
                        )}
                        {wp.hasGps && (
                            <MyTooltip title={<Translate id={"wps.Has GPS"} />}>
                                <Icon fontSize={"small"} className={"mr-8"} color={"primary"}>
                                    gps_fixed
                                </Icon>
                            </MyTooltip>
                        )}
                        {wp.isPin && (
                            <MyTooltip title={<Translate id={"wps.Is Pin"} />}>
                                <Icon fontSize={"small"} className={"mr-8"} color={"primary"}>
                                    fiber_pin
                                </Icon>
                            </MyTooltip>
                        )}
                        {wp.participantCount > 0 && (
                            <MyTooltip title={<Translate id={"wps.Participants"} />}>
                                <span className={"items-center flex w-32 text-xs"}>
                                    <Icon fontSize={"inherit"} color={"disabled"}>
                                        people
                                    </Icon>
                                    <span className={" mr-1 ml-1"}>{wp.participantCount}</span>
                                </span>
                            </MyTooltip>
                        )}
                        {wp.attachmentCount > 0 && (
                            <MyTooltip title={<Translate id={"wps.Attachments"} />}>
                                <span className={"items-center flex w-32 text-xs"}>
                                    <Icon fontSize={"inherit"} color={"disabled"}>
                                        attach_file
                                    </Icon>
                                    <span className={" mr-1 ml-1"}>{wp.attachmentCount}</span>
                                </span>
                            </MyTooltip>
                        )}
                        {wp.commentCount > 0 && (
                            <MyTooltip title={<Translate id={"wps.Comments"} />}>
                                <span className={"items-center flex w-32 text-xs"}>
                                    <Icon fontSize={"inherit"} color={"disabled"}>
                                        comment
                                    </Icon>
                                    <span className={" mr-1 ml-1"}>{wp.commentCount}</span>
                                </span>
                            </MyTooltip>
                        )}
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default WPItem;
