import {Icon} from "@material-ui/core";
import {grey, red} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import type {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {FuseLoading} from "../../@fuse";
import {SubmitButton} from "./SubmitButton";

export type EmptyPropTypes = {
    label?: ReactNode;
    /**
     * Align
     */
    left?: boolean;
    /**
     * {message: string}
     */
    error?: any;
    dense?: boolean;
    loading?: boolean;
    /**
     * data-cu
     */
    dc?: string;
    className?: string;
    /**
     * Icon Node
     */
    icon?: ReactNode;
    actions?: ReactNode;
    noIcon?: boolean;
    refresh?: () => void;
};

export function EmptyView({label, left, icon, className, error, dense, loading, dc, noIcon, refresh, actions}: EmptyPropTypes) {
    const {t} = useTranslation("errors");

    return loading ? (
        <FuseLoading />
    ) : (
        <div
            data-cy={dc}
            className={clsx(
                className,
                noIcon || dense ? "py-12" : "py-16",
                "flex flex-col flex-grow justify-center emptyPlaceholder",
                !left && " items-center"
            )}>
            {noIcon ? null : icon ? (
                icon
            ) : (
                <Icon style={{fontSize: dense ? 32 : 56, color: error ? red[600] : grey[300]}}>{error ? "warning" : "error"}</Icon>
            )}

            <div className={clsx(dense && "py-4", noIcon && "pt-0", !noIcon && !dense && "pt-32")}>
                <Typography
                    data-cy={error ? "error-message" : "empty-data"}
                    variant={dense ? "body1" : "h6"}
                    className={clsx(left ? "text-left" : "text-center", "truncate text-grey w-full")}>
                    {error ? (
                        <>
                            {error.message ? error.message : t("faildRequest")}
                            <br />
                            {error.response &&
                                error.response.data.message &&
                                // @ts-ignore
                                t([`errorMessages.${error.response.data.message}`, error.response.data.message])}
                        </>
                    ) : label ? (
                        label
                    ) : (
                        t("noData")
                    )}
                </Typography>
            </div>

            {refresh && error && (
                <SubmitButton loading={loading} startIcon={<Icon>refresh</Icon>} className={"mt-24"} onClick={refresh}>
                    {t("refresh")}
                </SubmitButton>
            )}

            {actions && <> {actions} </>}
        </div>
    );
}
