import {lazy} from "react";

export const ProjectsConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/projectDashboard",
            component: lazy(() => import("./dashboard/Dashboard")),
        },
        {
            path: "/project/:id/",
            component: lazy(() => import("./single/ProjectPage")),
        },
        {
            path: "/myProjects",
            component: lazy(() => import("./list/Page")),
        },
        {
            path: "/projects",
            component: lazy(() => import("./list/Page")),
        },
        {
            path: "/taskStats",
            component: lazy(() => import("./TaskStats/Page")),
        },
        {
            path: "/pieceRatePay",
            component: lazy(() => import("./pieceRate/Page")),
        },
        {
            path: "/myPieceRatePay",
            component: lazy(() => import("./MyPieceRate/Page")),
        },
        {
            path: "/pieceRateStats",
            component: lazy(() => import("./usersPayment/Page")),
        },
        {
            path: "/redoneProjects",
            component: lazy(() => import("./RedoneProjects/Page")),
        },
        {
            path: "/myRedoneProjects",
            component: lazy(() => import("./RedoneProjects/Page")),
        },
    ],
};
