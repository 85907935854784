import {FormControlLabel, IconButton, InputLabel, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import type {Dispatch, FC, SetStateAction} from "react";
import {TwitterPicker} from "react-color";
import {useTranslation} from "react-i18next";
import type {DGColType, ViewType} from "../types";
import {columnViewEnum} from "../types";

const sortableTypes: ViewType[] = ["text", "number", "date", "singleOption", "currency"];

type Props = {
    tempCol: Omit<DGColType, "id">;
    setTempCol: Dispatch<SetStateAction<any>>;
    errors?: Record<string, string | undefined>;
};

const readOnlyColViews = ["thumb"];

const DGColForm: FC<Props> = ({errors, tempCol, setTempCol}) => {
    const {t} = useTranslation(["datagrid", "_"]);
    const onChangeType = (e) => {
        setTempCol((c) => ({...c, sort: sortableTypes.includes(e.target.value), viewType: e.target.value as ViewType}));
    };

    return (
        <>
            {/* region label */}
            <div className={"p-12"}>
                <TextField
                    error={!!errors?.label}
                    helperText={errors?.label}
                    required
                    autoFocus
                    label={t("label_col")}
                    value={tempCol.label}
                    onChange={(e) => setTempCol((c) => ({...c, label: e.target.value}))}
                    fullWidth
                />
            </div>

            {/* endregion */}
            {/* region col type */}
            <div className={"px-12"}>
                <FormControl margin={"normal"} fullWidth>
                    <InputLabel>{t("type_col")}</InputLabel>
                    <Select fullWidth value={tempCol.viewType || "text"} onChange={onChangeType}>
                        {columnViewEnum.map(
                            (v) =>
                                !readOnlyColViews.includes(v) && (
                                    <MenuItem key={v} value={v}>
                                        {/* // @ts-ignore because ts dones't get it*/}
                                        {t(`colTypes.${v}`)}
                                    </MenuItem>
                                )
                        )}
                    </Select>
                </FormControl>

                {(tempCol.viewType === "singleOption" || tempCol.viewType === "multiOption") && (
                    <div className={"bg-gray-100 rounded-12 p-12"}>
                        <div className="flex items-center justify-between p-21">
                            <strong>{t("defaultOptions")}</strong>
                            <Button
                                variant={"outlined"}
                                onClick={() => {
                                    setTempCol((c) => ({...c, options: c.options ? [...c.options, ""] : [""]}));
                                }}
                                startIcon={<Icon children={"add"} />}
                                size={"small"}>
                                {t("addOption")}
                            </Button>
                        </div>
                        <div className="flex flex-col">
                            {tempCol.options && (
                                <>
                                    {tempCol.options.map((o, index) => (
                                        <>
                                            <TextField
                                                key={index}
                                                autoFocus={index + 1 === tempCol.options?.length}
                                                fullWidth
                                                margin={"dense"}
                                                placeholder={t("option") + " " + (index + 1)}
                                                value={o}
                                                onChange={(e) => {
                                                    // @ts-ignore
                                                    const t = [...tempCol.options];
                                                    t[index] = e.target.value;
                                                    setTempCol((c) => ({...c, options: t}));
                                                }}
                                                onKeyDown={(ev) => {
                                                    if (ev.key === "Enter") {
                                                        // Do code here
                                                        ev.preventDefault();
                                                        setTempCol((c) => ({...c, options: c.options ? [...c.options, ""] : [""]}));
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => {
                                                                const t = [...tempCol.options];
                                                                t.splice(index, 1);
                                                                setTempCol((c) => ({...c, options: t}));
                                                            }}
                                                            size={"small"}>
                                                            <Icon children={"close"} />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* endregion */}
            {/* region sortable */}
            {tempCol.viewType !== "checkbox" && (
                <FormControlLabel
                    className={"pl-12 block"}
                    control={<Checkbox checked={tempCol.sort || false} onChange={(e) => setTempCol((c) => ({...c, sort: e.target.checked}))} />}
                    label={t("sortable")}
                />
            )}
            {/* endregion */}
            {/* region disable edit */}
            <FormControlLabel
                className={"pl-12 block"}
                control={
                    <Checkbox checked={tempCol.disableEdit || false} onChange={(e) => setTempCol((c) => ({...c, disableEdit: e.target.checked}))} />
                }
                label={t("disableEdit")}
            />
            {/* endregion */}
            {/* region color */}

            <div className="flex items-center px-12 pt-12">
                <div className={"min-w-32 mr-8 flex  items-center justify-center"}>
                    <div className={"w-20 h-20 rounded-4 bg-gray-50 "} style={{backgroundColor: tempCol.color?.hex}}></div>
                </div>
                {t("colColor")}
            </div>
            <TwitterPicker
                colors={[
                    "#999999",
                    "#f3f4f6",
                    "#FFFFFF",
                    "#D9B3A9",
                    "#FFC266",
                    "#FFE566",
                    "#c3f6c9",
                    "#D9F2FF",
                    "#66C9FF",
                    "#ffa1bd",
                    "#FFD9E5",
                    "#E5C2FF",
                ]}
                color={tempCol.color}
                triangle={"hide"}
                width={320}
                onChangeComplete={(color) => setTempCol({...tempCol, color: color})}
            />
            {/* endregion */}
        </>
    );
};

export default DGColForm;
