import axios, {commonPrefix} from "@api";
import {IconButton, InputAdornment, Popover} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import {LocalTimeStamp, MyChip, SubmitButton, UserView} from "@ui";
import _ from "lodash";
import {useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import useSWR from "swr";
import type {SearchType} from "../../../../types";
import {formatSizeUnits} from "../../../../utils/utilities";
import {useDGDef, useDGFilters} from "../hooks/hooks";

import type {CategoryNumber, DGColType, filterType} from "../types";
import {getDataSizes} from "../utils";

export const NumberLabel = ({value}: {value: CategoryNumber}) => {
    return (
        <span className={"font-bold"}>
            {value.min} - {value.max}
        </span>
    );
};

export const SizeLabel = ({index}: {index: number}) => {
    return index === -1 ? (
        <strong>{"> 4GB"}</strong>
    ) : index === 0 ? (
        <>{"< 1MB"}</>
    ) : (
        <strong>{formatSizeUnits(getDataSizes[index - 1], 0) + " - " + formatSizeUnits(getDataSizes[index], 0)}</strong>
    );
};

export const DateLabel = ({value}: {value: string}) => {
    const parsedV = JSON.parse(value);
    const isDay = !!parsedV.day && parsedV.day > 0;
    const isMonth = !isDay && parsedV.month > -1;
    const isYear = !isMonth && !isDay;
    return isYear ? parsedV.year : isMonth ? parsedV.year + "-" + (parsedV.month + 1) : parsedV.year + "-" + (parsedV.month + 1) + "-" + parsedV.day;
};

const strongConditions = ["lt", "gt", "endsWith", "startsWith", "neq", "contains"];

type Saved = {
    data: SearchType;
    id: string;
    key: string;
    name: string;
    type: "datagridFilters";
};

const ShowFilters = ({setTextSearch, textSearch}: {setTextSearch: (text: string) => void; textSearch: string}) => {
    const {t} = useTranslation(["datagrid", "_"]);
    const {cols, id} = useDGDef();
    const [savingLoading, setSavingLoading] = useState(false);
    const [openSave, setOpenSave] = useState(null);
    const [saveName, setSaveName] = useState("");

    const {filters, setFilters, onRemoveFilter, count} = useDGFilters();

    const {data, mutate} = useSWR<Saved[]>(`${commonPrefix}/myData?key=datagridFilters&type=${id}`, {revalidateOnMount: true});

    const displayFilter = (filter: filterType, col: DGColType) => {
        if (col.viewType === "user" && _.isArray(filter.value)) {
            return filter.value.map((v) => {
                return <UserView className={"ml-12"} user={v} />;
            });
        }
        if (strongConditions.includes(filter.condition)) {
            // @ts-ignore
            return <strong>{filter.value}</strong>;
        }
        if (filter.condition === "same" || filter.condition === "before" || filter.condition === "after") {
            return (
                <strong>
                    <LocalTimeStamp datetime={filter.value.dateFrom} />
                </strong>
            );
        }
        switch (filter.condition) {
            case "eq":
                switch (typeof filter.value) {
                    case "string":
                        return <strong>{filter.value}</strong>;
                    case "object":
                        return <strong>{JSON.stringify(filter.value)}</strong>;
                    default:
                        return filter.value;
                }
            case "range":
                return (
                    <strong>
                        <LocalTimeStamp datetime={filter.value.dateFrom} /> - <LocalTimeStamp datetime={filter.value.dateTo} />
                    </strong>
                );
            case "between":
                return (
                    <strong>
                        {filter.value.from} - {filter.value.to}
                    </strong>
                );
            case "option":
                return _.isArray(filter.value) ? <strong>{filter.value.join(", ")}</strong> : <strong>{JSON.stringify(filter.value)}</strong>;

            default:
                return JSON.stringify(filter.value);
        }
    };

    const onSaveSearch = () => {
        setSavingLoading(true);
        const data = {
            key: "datagridFilters",
            type: id,
            name: saveName,
            data: {filters: filters},
        };
        axios
            .put(`${commonPrefix}/myData`, data)
            .then(() => {
                setOpenSave(null);
                setSaveName("");
                toast.success(t("Saved!"));
                mutate();
            })
            .finally(() => setSavingLoading(false));
    };

    const onDeleteSaved = (id) => {
        setSavingLoading(true);
        axios
            .delete(`${commonPrefix}/myData/${id}`)
            .then(() => {
                mutate();
            })
            .finally(() => setSavingLoading(false));
    };

    return (
        <>
            <div className={"bg-white border-t-1 border-l-1 border-r-1 border-gray-200 min-h-48 items-center px-12 flex justify-between"}>
                <div className="flex items-center flex-wrap">
                    <strong className={"text-18 mr-12 align-middle"}>{t("results", {resultLength: count})}</strong>
                    <div>
                        <TextField
                            InputProps={{
                                endAdornment: textSearch !== "" && (
                                    <InputAdornment position="end">
                                        <IconButton size={"small"} data-cy={"clear-search"} onClick={() => setTextSearch("")}>
                                            <Icon fontSize={"small"} className={"text-dark"} children={"close"} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize={"small"} children={"search"} />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                                className: "border-1 border-gray-200  pb-0 pl-8 rounded-8",
                            }}
                            inputProps={{
                                className: "py-2",
                            }}
                            className={"max-w-224 mr-12"}
                            placeholder={t("searchTable")}
                            size={"small"}
                            value={textSearch}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />
                    </div>
                    {filters.map((f) => {
                        const col = cols.find((c) => c.id === f.colId);
                        if (!col) return null;
                        return (
                            <span
                                key={f.colId}
                                className={
                                    "flex flex-shrink-0 my-4 overflow-ellipsis items-center mr-4 pl-2 border-gray-200 border-1  rounded-8 bg-grey-lighter"
                                }>
                                <span className={"flex-shrink-0 pr-8 "}>
                                    <span className={"align-middle"}>{col?.label} </span>

                                    <span className={"text-gray-400 align-middle"}>
                                        {col.viewType === "checkbox" ? (f.condition === "empty" ? "Not Checked" : "Checked") : f.condition}
                                    </span>
                                </span>
                                {f.condition !== "empty" && f.condition !== "notEmpty" && <>{displayFilter(f, col)}&nbsp;&nbsp;</>}
                                <button
                                    className={
                                        "text-red-lighter  hover:text-red-dark duration-200  p-0 rounded-r-4 h-full flex items-center justify-center"
                                    }
                                    onClick={() => onRemoveFilter(col.id)}>
                                    <Icon fontSize={"small"} className={"align-middle"} children={"close"} />
                                </button>
                            </span>
                        );
                    })}
                </div>

                {filters.length > 0 && (
                    <div className={"flex"}>
                        <Button
                            variant={"text"}
                            startIcon={<Icon>save</Icon>}
                            className={"mr-2"}
                            size={"small"}
                            // @ts-ignore
                            onClick={(e) => setOpenSave(e.currentTarget)}>
                            {t("save")}
                        </Button>
                        <Popover
                            onClose={() => {
                                setOpenSave(null);
                                setSaveName("");
                            }}
                            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                            open={Boolean(openSave)}
                            anchorEl={openSave}>
                            <div className="flex items-center p-12">
                                <TextField
                                    autoFocus={true}
                                    size={"small"}
                                    value={saveName}
                                    onChange={(e) => setSaveName(e.target.value)}
                                    label={t("name")}
                                />
                                <SubmitButton disabled={saveName.trim() === ""} size={"small"} loading={savingLoading} onClick={onSaveSearch} success>
                                    {t("save")}
                                </SubmitButton>
                            </div>
                        </Popover>

                        <Button
                            size={"small"}
                            onClick={() => setFilters([])}
                            className={"text-red align-middle"}
                            startIcon={<Icon children="search_off" />}>
                            {t("clear")}
                        </Button>
                    </div>
                )}
            </div>

            {data && data.length > 0 && (
                <div className={"p-4"}>
                    {data.map((x) => (
                        <MyChip
                            deleteIcon={<Icon fontSize={"small"}>delete</Icon>}
                            onDelete={() => onDeleteSaved(x.id)}
                            className={"mx-2 mb-2"}
                            onClick={() => setFilters(x.data.filters)}
                            label={x.name}
                            size={"small"}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default ShowFilters;
