import {ReactNode, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {EmptyView, SubmitButton} from "@ui";
import {useLocation} from "react-router-dom";
import {useUpdateEffect} from "ahooks";
import myaxios, {commonPrefix} from "@api";
import {browserName, browserVersion, osName, osVersion} from "react-device-detect";
import type {ErrorResType} from "./types";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {Icon} from "@material-ui/core";

function ErrorFallback(props) {
    const {error, resetErrorBoundary} = props;

    const {pathname} = useLocation();
    const {t} = useTranslation("errors");
    const [loading, setLoading] = useState(false);
    const [hasSent, setHasSent] = useState(false);

    const logError = () => {
        setLoading(true);
        const data: Partial<ErrorResType> = {
            message: error.message,
            errorType: error.name,
            environment: process.env.NODE_ENV,
            browserInfo: {
                name: browserName,
                version: browserVersion,
                platform: osName + " " + osVersion,
            },
            url: pathname,
            feedback: "",
            stack: error.stack,
            timestamp: +new Date(),
        };
        myaxios
            .post(`${commonPrefix}/error`, data)
            .then(() => {
                toast.error(t("reportedError"));
                setHasSent(true);
            })
            .finally(() => setLoading(false));
    };

    useUpdateEffect(() => {
        if (error) {
            resetErrorBoundary();
        }
    }, [pathname]);

    return (
        <EmptyView
            error={error}
            refresh={resetErrorBoundary}
            actions={
                <SubmitButton disabled={hasSent} onClick={logError} success className={"mt-20"} startIcon={<Icon>report</Icon>} loading={loading}>
                    {t("reportBtn")}
                </SubmitButton>
            }
        />
    );
}

const ErrorReporter = ({children}: {children: ReactNode}) => {
    const {pathname} = useLocation();
    const {t} = useTranslation("errors");
    const [hasSent, setHasSent] = useState(false);

    const logError = (error, info) => {
        if (hasSent) return;
        const data: Partial<ErrorResType> = {
            message: error.message,
            errorType: error.name,
            environment: process.env.NODE_ENV,
            browserInfo: {
                name: browserName,
                version: browserVersion,
                platform: osName + " " + osVersion,
            },
            url: pathname,
            feedback: "",
            stack: info.componentStack,
        };
        myaxios.post(`${commonPrefix}/error`, data).then(() => {
            setHasSent(true);
            toast.error(t("reportedError"));
        });
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorReporter;
