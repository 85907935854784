/**
 *
 - fixme Drag and drop row, col

 - NOTDOING Coloring options. I want to keep the options as strings
 &nbsp;

 &nbsp;

 |Form limit|
 |-|-|
 |Daily|One time everyday|
 |Once|User can only fill it once|
 |No limit|User can fill many times|


 */
import {commonPrefix} from "@api";
import {turnOn} from "@fuseActions";
import Icon from "@material-ui/core/Icon";
import {DialogWrapper, EmptyView} from "@ui";
import {useDeepCompareEffect} from "ahooks";
import {useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import useSWR from "swr";
import uniqid from "uniqid";
import {FuseSuspense} from "../../../@fuse";
import {useAuth} from "../../../app/auth/AuthProvider";
import DataGridContent from "./DataGridContent";
import {db} from "./db";
import UnsavedChanges from "./extra/UnsavedChanges";
import type {DGColsCType, DGSettingsType} from "./hooks/hooks";
import {DGColContext, DGDefContext} from "./hooks/hooks";
import useDatagrids from "./hooks/useDatagrids";
import EditRecord from "./table/row/EditRecord";
import type {DGColType, DGResType, DGRowType} from "./types";
import {colDefaultMinWidth} from "./types";

const DataGrid = ({
    id,
    onSave,
    defaultCols,
    defaultRows,
}: {
    id: string;
    onSave?: (id: string, title: string) => void;
    defaultCols?: DGColType[];
    defaultRows?: DGRowType[];
}) => {
    // region hooks
    const dispatch = useDispatch();
    const unsavedChanges = useSelector(({fuse}) => fuse.dialogs["unsavedChanges"]);

    const {t} = useTranslation(["datagrid", "_"]);
    const {dataGrid, addDataGrid, updateDataGrid} = useDatagrids(id);

    const {user} = useAuth();
    // endregion

    // region states

    const [colsWidth, setColsWidth] = useState<Record<string, number>>();
    const [hoveredCol, setHoveredCol] = useState<string>("");
    // endregion

    const {
        data: serverData,
        error,
        isLoading,
    } = useSWR<DGResType>(id && !defaultCols && `${commonPrefix}/datagrids/${id}`, {revalidateOnMount: true});

    // useDeepCompareEffect(() => {
    //     if (dataGrid && dataGrid.colsWidth) {
    //         console.log("compare", dataGrid.colsWidth);
    //         setColsWidth(dataGrid.colsWidth);
    //     }
    // }, [dataGrid]);

    useDeepCompareEffect(() => {
        const serverCols = serverData && serverData.data && serverData.data.cols ? serverData.data.cols : [];
        const serverRows = serverData && serverData.data && serverData.data.rows ? serverData.data.rows : [];

        async function getDataGrid() {
            if (id && serverData && user) {
                const lastTimestamp = serverData.activeTime || serverData.timestamp;
                const dg = await db.dataGrids.where("id").equals(id).first();
                if (!dg) {
                    setColsWidth(serverCols.reduce((acc, value) => ({...acc, [value.id]: value.minWidth}), {}));
                    await addDataGrid(
                        {
                            title: serverData.title,
                            permission: serverData.permission,
                            cols: serverCols,
                            colsWidth: serverCols.reduce((acc, value) => ({...acc, [value.id]: value.minWidth}), {}),
                            id,
                            isOwner: user.id === serverData.user?.id,
                            activeTime: lastTimestamp,
                        },
                        serverRows
                    );
                } else if (dg.activeTime && lastTimestamp > dg.activeTime) {
                    if (dg.lastModified) {
                        if (unsavedChanges) return;
                        const newRows = await db.dataGridRows
                            .where("dataGridId")
                            .equals(id)
                            .and((x) => x.activeTime > 0)
                            .toArray();

                        const rowIds = newRows.map((x) => x.id);
                        const serverRowsIn: DGRowType[] = [];
                        serverRows.forEach((x) => rowIds.includes(x.id) && Object.keys(x).length > 3 && user.id !== x.u && serverRowsIn.push(x));

                        console.log(serverRowsIn);

                        if (serverRowsIn.length === 0) {
                            await updateDataGrid(
                                {
                                    title: serverData.title,
                                    permission: serverData.permission,
                                    cols: serverCols,
                                    isOwner: user.id === serverData.user?.id,
                                    activeTime: lastTimestamp,
                                },
                                serverRows.filter((x) => !rowIds.includes(x.id)).map((x) => ({...x, dataGridId: id}))
                            );
                        } else {
                            toast.error("You have unsaved changes.");
                            dispatch(turnOn("unsavedChanges"));
                        }
                    } else {
                        await updateDataGrid(
                            {
                                title: serverData.title,
                                permission: serverData.permission,
                                cols: serverCols,
                                isOwner: user.id === serverData.user?.id,
                                activeTime: lastTimestamp,
                            },
                            serverRows.map((x) => ({...x, dataGridId: id}))
                        );
                    }
                }
            }
            // for read only mode
            else if (id && defaultCols) {
                const dg = await db.dataGrids.where("id").equals(id).first();
                const lastTimestamp = +new Date();
                if (!dg) {
                    await addDataGrid(
                        {
                            title: "",
                            permission: "readonly",
                            cols: defaultCols,
                            colsWidth: defaultCols.reduce((acc, value) => ({...acc, [value.id]: value.minWidth}), {}),
                            id,
                            isOwner: false,
                            activeTime: lastTimestamp,
                        },
                        defaultRows ? defaultRows.map((x) => ({...x, dataGridId: id})) : []
                    );
                } else if (dg.activeTime && lastTimestamp > dg.activeTime) {
                    await updateDataGrid(
                        {
                            cols: defaultCols,
                            activeTime: lastTimestamp,
                        },
                        defaultRows ? defaultRows.map((x) => ({...x, dataGridId: id})) : []
                    );
                }
            }
        }

        getDataGrid();
    }, [serverData]);

    if (!dataGrid && error) return <EmptyView error={error} />;
    if (!dataGrid) return <EmptyView loading={isLoading || !dataGrid} />;

    const {cols, permission, isOwner, hiddenCols} = dataGrid;

    const colPermission = permission !== "manage" && !isOwner;

    const onChangeSetting = (key: keyof DGSettingsType, value: any) => {
        updateDataGrid({[key]: value, lastModified: +new Date()});
    };

    const onPublishSettings = (id, title) => {
        if (onSave) {
            onSave(id, title);
        }
    };

    const onAddCol = (col: Omit<DGColType, "id">) => {
        if (colPermission) return;
        let colId = "c0";
        if (cols && cols.length > 0) {
            const lastId = cols[cols.length - 1].id;
            colId = "c" + (Number(lastId.slice(1)) + 1);
        }

        onChangeSetting("cols", [
            ...dataGrid.cols,
            {
                ...col,
                minWidth: colDefaultMinWidth,
                modifiedTime: +new Date(),
                id: colId,
            },
        ]);
    };

    const onChangeCol = (col: DGColType) => {
        if (colPermission) return;
        const t = [...dataGrid.cols];
        const index = t.findIndex((x) => x.id === col.id);
        t[index] = {...col, modifiedTime: +new Date()};
        onChangeSetting("cols", t);
    };

    const onDeleteCol = (id: string) => {
        if (colPermission) return;
        const t = [...dataGrid.cols];
        const index = t.findIndex((c) => c.id === id);
        t[index].deleted = true;
        onChangeSetting("cols", t);
    };

    const onResizeCol = (colId: string, width: any) => {
        const t = {...colsWidth};

        t[colId] = width;

        setColsWidth(t);
    };

    const onToggleCol = (colId: string) => {
        if (hiddenCols) {
            if (hiddenCols.has(colId)) {
                hiddenCols.delete(colId);
            } else {
                hiddenCols.add(colId);
            }
            updateDataGrid({hiddenCols});
        } else {
            updateDataGrid({hiddenCols: new Set([colId])});
        }
    };

    const isColEditable = (colId: string) => {
        const col = cols.find((c) => c.id === colId);
        if (!col) return false;
        return isOwner || (permission !== "readonly" && permission !== "disabled" && permission !== "add" && !col.disableEdit);
    };

    const value = {
        ...dataGrid,
        tempColsWidth: colsWidth,
        onPublishSettings,
        onChangeSetting,
        onAddCol,
        onChangeCol,
        onDeleteCol,
    };

    const colContextValue: DGColsCType = {
        // colsConf,
        hoveredCol,
        setHoveredCol,
        // rowsConf,
        onToggleCol,
        onResizeCol,
        isCellEditable: isColEditable,
    };

    // console.log({value, dataGrid});

    return (
        <FuseSuspense>
            <DGDefContext.Provider value={value}>
                <DialogWrapper maxWidth={"xl"} name={"unsavedChanges"}>
                    <UnsavedChanges />
                </DialogWrapper>

                {error || isLoading ? (
                    <EmptyView loading={isLoading} error={error} />
                ) : permission === "disabled" && !isOwner ? (
                    <EmptyView icon={<Icon className={"text-gray-300 text-44"}>visibility_off</Icon>} label={t("ownerHasDisabled")} />
                ) : (
                    <DGColContext.Provider value={colContextValue}>
                        <DialogWrapper name={"newDGRecord"} header={t("newRecord")}>
                            {(onClose) => (
                                <EditRecord
                                    row={{
                                        id: uniqid(),
                                    }}
                                    onClose={onClose}
                                />
                            )}
                        </DialogWrapper>
                        <DataGridContent />
                    </DGColContext.Provider>
                )}
            </DGDefContext.Provider>
        </FuseSuspense>
    );
};

export default DataGrid;
