import myaxios, {commonPrefix, userPrefix} from "@api";
import {turnOff} from "@fuseActions";
import {DialogContent, DialogTitle, FormControlLabel, Switch} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import {LocalTimeStamp, MyAvatar, SubmitButton, useDGDef, UserView} from "@ui";
import clsx from "clsx";
import {useLiveQuery} from "dexie-react-hooks";
import {isEqual} from "lodash";
import type {FC} from "react";
import {useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useSWR from "swr";
import {useAuth} from "../../../../app/auth/AuthProvider";
import {dateFormatWithTime} from "../../../../variables";
import {db} from "../db";
import useDatagrids from "../hooks/useDatagrids";
import DGCell from "../table/cell/DGCell";
import type {DGResType, DGRowType} from "../types";
import {colDefaultMinWidth} from "../types";

const UserAvatar = ({id}: {id: string}) => {
    const {data} = useSWR(`${userPrefix}/user/${id}/profile`, {revalidateOnMount: true});
    const [open, setOpen] = useState<HTMLButtonElement | null>(null);

    return (
        <>
            <IconButton size={"small"} onClick={(e) => setOpen(e.currentTarget)}>
                <MyAvatar user={data} size={16} />
            </IconButton>
            <Popover onClose={() => setOpen(null)} anchorEl={open} open={Boolean(open)}>
                <div className="p-12">
                    <UserView user={data} />
                </div>
            </Popover>
        </>
    );
};

const UnsavedChanges: FC = () => {
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(false);
    const {t} = useTranslation(["datagrid", "_"]);
    const {id, cols, colsWidth} = useDGDef();
    const {user} = useAuth();
    const {clearRowConflict, updateDataGrid, clearConflict} = useDatagrids(id);
    const [showAll, setShowAll] = useState(true);
    const [loading, setLoading] = useState<string | null>(null);

    const newRows = useLiveQuery(() =>
        id
            ? db.dataGridRows
                  .where("dataGridId")
                  .equals(id)
                  .and((x) => x.activeTime > 0)
                  .toArray()
            : []
    );

    const {data: serverData} = useSWR<DGResType>(id && `${commonPrefix}/datagrids/${id}`, {revalidateOnMount: true});
    const serverRows = serverData && serverData.data && serverData.data.rows ? serverData.data.rows : [];

    const onUseMine = (row: DGRowType) => {
        const temp = {...row};
        temp["t"] = temp.activeTime;
        temp["u"] = user?.id;
        delete temp.activeTime;
        delete temp.dataGridId;
        setLoading(row.id);

        myaxios
            .put(`${commonPrefix}/datagridData/${id}`, {rows: [temp]})
            .then(({data}) => {
                const serverRows = data && data.data && data.data.rows ? data.data.rows : [];
                toast.success(t("toast.saved"));
                clearRowConflict(row);

                if (!newRows) return;

                const rowIds = newRows.map((x) => x.id);
                const serverRowsIn: DGRowType[] = [];
                serverRows.forEach((x) => rowIds.includes(x.id) && serverRowsIn.push(x));

                newRows.forEach((x) => {
                    delete x.activeTime;
                    delete x.dataGridId;
                });

                console.log({serverRowsIn, newRows});

                if (!newRows || newRows.length === 1) {
                    dispatch(turnOff("unsavedChanges"));
                    updateDataGrid(
                        {
                            title: data.title,
                            permission: data.permission,
                            cols: data.data && data.data.cols ? data.data.cols : [],
                            isOwner: user?.id === data.user?.id,
                            activeTime: data.activeTime,
                            lastModified: undefined,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        data.data.rows ? data.data.rows.map(({activeTime, ...x}) => ({...x, dataGridId: id})) : []
                    );
                }
            })
            .finally(() => setLoading(null));
    };

    const clearMine = async (row: DGRowType) => {
        await db.dataGridRows.put({...row, dataGridId: id});

        console.log(newRows);

        if (!newRows || newRows.length === 1) {
            dispatch(turnOff("unsavedChanges"));
            clearConflict();
        }
    };

    return (
        <div>
            <DialogTitle>
                <div className={"flex justify-between w-full"}>
                    {t("unsavedChanges")}
                    <FormControlLabel
                        className={"ml-12"}
                        control={<Switch checked={showAll} onChange={() => setShowAll(!showAll)} title={"Show all columns"} size={"small"} />}
                        label="Show all columns"
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                {newRows
                    ? newRows.map((row) => {
                          const serverRow = serverRows.find((x) => x.id === row.id && x.u !== user?.id);

                          return (
                              <div key={row.id} className={""}>
                                  {serverRow && Object.keys(serverRow).length > 3 && (
                                      <div className={"mb-32"}>
                                          <div className={"flex"}>
                                              <div className={clsx(" flex-shrink-0 min-w-52 border-t flex flex-col whitespace-nowrap  relative")}>
                                                  <div className={"h-28 px-2 border-l-1 border-b-2 border-r-1 flex "}></div>
                                                  <div className={"h-28 px-2  border-r-1 border-l-1 flex items-center  text-right "}>
                                                      Server changes:
                                                      <LocalTimeStamp
                                                          className={"text-xs text-gray-500 mx-8"}
                                                          datetime={serverRow.t}
                                                          format={dateFormatWithTime}
                                                      />
                                                      <UserAvatar id={serverRow.u} />
                                                  </div>
                                                  <div className={"h-28 px-2  border-1 text-right"}>
                                                      Your Changes:&nbsp;
                                                      <LocalTimeStamp
                                                          className={"text-xs text-gray-500"}
                                                          datetime={row.activeTime}
                                                          format={dateFormatWithTime}
                                                      />
                                                  </div>
                                              </div>
                                              {cols.map((col, i) => {
                                                  const hasConflict = !isEqual(serverRow[col.id], row[col.id]) && serverRow[col.id]?.u !== user?.id;
                                                  return (
                                                      (hasConflict || showAll) && (
                                                          <div
                                                              key={col.id}
                                                              style={{
                                                                  width: colsWidth[col.id] ? colsWidth[col.id] : colDefaultMinWidth,
                                                              }}
                                                              className={clsx(
                                                                  " flex-shrink-0 min-w-52 border-t flex flex-col whitespace-nowrap  relative",
                                                                  hasConflict ? "font-bold bg-yellow-lightest" : "opacity-50"
                                                              )}>
                                                              <div className={clsx("h-28 px-2 font-bold border-r-1 border-b-2 flex ")}>
                                                                  {col.label}
                                                              </div>
                                                              <div
                                                                  className={clsx(
                                                                      hasConflict && "text-orange-dark",
                                                                      "h-28 px-2 border-r-1 flex border-b-1"
                                                                  )}>
                                                                  <DGCell
                                                                      setFocusedEdit={() => {}}
                                                                      nextFocus={row.id + "-" + cols?.[i + 1]?.id}
                                                                      col={col}
                                                                      row={serverRow}
                                                                      focusedEdit={false}
                                                                  />
                                                              </div>
                                                              <div
                                                                  className={clsx(
                                                                      hasConflict && "text-green-dark",
                                                                      "h-28 px-2 border-r-1 flex border-b-1"
                                                                  )}>
                                                                  <DGCell
                                                                      setFocusedEdit={() => {}}
                                                                      nextFocus={row.id + "-" + cols?.[i + 1]?.id}
                                                                      col={col}
                                                                      row={row}
                                                                      focusedEdit={false}
                                                                  />
                                                              </div>
                                                          </div>
                                                      )
                                                  );
                                              })}
                                          </div>
                                          <div className={"pt-12"}>
                                              <Button
                                                  onClick={() => clearMine(serverRow)}
                                                  size={"small"}
                                                  variant={"text"}
                                                  className={"mr-12 text-red px-12"}>
                                                  Clear my changes
                                              </Button>
                                              <SubmitButton
                                                  loading={loading === row.id}
                                                  onClick={() => onUseMine(row)}
                                                  startIcon={<Icon>save</Icon>}
                                                  size={"small"}
                                                  success>
                                                  Use my changes
                                              </SubmitButton>
                                          </div>
                                      </div>
                                  )}
                              </div>
                          );
                      })
                    : null}
            </DialogContent>
        </div>
    );
};

export default UnsavedChanges;
