import axios, {pmsPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_TEMPLATES = "[COMMERCE TEMPLATE] GET";
export const SET_TEMPLATES_SEARCH_TEXT = "[COMMERCE TEMPLATE] SET SEARCH TEXT";
export const SELECT_TEMPLATE = "[COMMERCE TEMPLATE] SELECT_TEMPLATE";

export function getTemplates(params) {
    const request = axios.get(`${pmsPrefix}/templates`, {params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_TEMPLATES,
                payload: response.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function selectTemplate(payload) {
    return {
        type: SELECT_TEMPLATE,
        payload,
    };
}
