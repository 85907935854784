import useCommercialStore from "./useCommercialStore";

function useCommercial() {
    const store: any = useCommercialStore();

    return {
        userPay: store.item,
        onChangeUserPay: store.onChangeItem,
    };
}

export default useCommercial;
