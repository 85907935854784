import {Icon, IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {useToggle} from "ahooks";
import type {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import type {SWRResponse} from "swr";
import useSWR from "swr";
import ExcelBtn from "./components/ExcelBtn";
import SaveSearch from "./components/SaveSearch";
import SearchBox from "./components/SearchBox";
import FiltersProvider from "./FiltersProvider";
import Header from "./Header";
import type {FiltersContextType} from "./hooks/filtersContext";
import {useFilters} from "./hooks/filtersContext";
import {MainContext, useMainContext} from "./hooks/mainContext";
import ShowFilters from "./ShowFilters";
import type {MainProps} from "./types";

function FilterWrapper(props: {renderFilters: (filter: FiltersContextType) => ReactNode}) {
    const filter = useFilters();
    return <div className={"pt-12"}>{props.renderFilters(filter)}</div>;
}

function Inner(props: {children: ReactNode | ((props: {filterContext: FiltersContextType; swr: SWRResponse}) => ReactNode)}) {
    const {link} = useMainContext();

    const filterContext = useFilters();
    const swr = useSWR([link, filterContext.filters]);

    return <>{typeof props.children === "function" ? props.children({filterContext, swr}) : props.children}</>;
}

export const FilterableData = <TItem extends {id: string}>(props: MainProps<TItem>) => {
    const {
        link,
        headerOptions,
        disableTextSearch,
        disableSavingFilters,
        renderFilters,
        excel,
        children,
        // disablePagination,
        // extraHeader,
        // itemProps,
        // selectOptions,
        // renderItem,
    } = props;
    const {t} = useTranslation();
    const [sidebar, sidebarActions] = useToggle();
    const [saving, savingActions] = useToggle();

    return (
        <MainContext.Provider value={{saving, savingActions, sidebar, sidebarActions, link}}>
            <FiltersProvider<TItem>>
                <Header {...headerOptions} />

                <div className={"w-full mb-16"} data-cy={`${link}Wrapper`}>
                    {/* region show filters and open sidebar btn */}

                    <div className={"px-12 pb-12  md:flex w-full items-center"}>
                        <div className="flex">
                            {typeof renderFilters === "function" && (
                                <Button
                                    data-cy={"toggle-filter"}
                                    disableRipple
                                    variant={"contained"}
                                    className={"bg-white mr-12"}
                                    onClick={() => sidebarActions.toggle()}
                                    startIcon={<Icon>{sidebar ? "arrow_back" : "filter_alt"}</Icon>}>
                                    {sidebar ? t("Close Filters") : t("open Filters")}
                                </Button>
                            )}
                            {!disableTextSearch && <SearchBox />}
                        </div>
                        <ShowFilters noSaveSearch={disableSavingFilters} />
                    </div>

                    {/* endregion */}

                    <div className="flex w-full">
                        {/* region sidebar */}

                        {typeof renderFilters === "function" && (
                            <>
                                {/* region mobile sidebar btn */}
                                {sidebar && (
                                    <div className={"md:hidden"}>
                                        <IconButton
                                            onClick={() => sidebarActions.setLeft()}
                                            className={"absolute top-0 right-0 z-999 bg-white hover:bg-red-dark hover:text-white"}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                        <div
                                            onClick={() => sidebarActions.setLeft()}
                                            className={"bg-black opacity-50 absolute top-0 z-99 w-full h-full"}
                                        />
                                    </div>
                                )}
                                {/* endregion */}

                                <div
                                    className={
                                        sidebar
                                            ? "md:min-w-288 md:relative p-24 md:p-0 left-0 w-full md:w-auto top-0 absolute z-99 flex-shrink-0"
                                            : ""
                                    }>
                                    {sidebar && (
                                        <Card className="bg-white h-full pb-12  md:rounded-8 md:ml-12 overflow-visible">
                                            {!disableSavingFilters && <SaveSearch name={link} />}

                                            {!saving && <FilterWrapper renderFilters={renderFilters} />}

                                            {excel && <ExcelBtn<TItem> {...excel} />}
                                        </Card>
                                    )}
                                </div>
                            </>
                        )}
                        {/* endregion */}

                        {/* region content */}
                        <Card className="mx-12 flex-1">
                            {/*{selectOptions && <SelectBar name={link} actions={selectOptions.selectActions} />}*/}
                            <Inner children={children} />
                        </Card>
                        {/* endregion */}
                    </div>
                </div>
            </FiltersProvider>
        </MainContext.Provider>
    );
};
