/**
 *
 - fixme Add sub record
 - fixme Drag and drop col
 - fixme Drag and drop row

 &nbsp;

 - fixme When editing in a column that is sorted, the data changes
 */
import {Button, Dialog, Icon} from "@material-ui/core";
import {EmptyView} from "@ui";
// import {isSameDay, isSameMonth, isSameYear} from "date-fns";
import {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import DGFooter from "./footer/DGFooter";
import DGSettings from "./header/DGSettings";
import ShowFilters from "./header/ShowFilters";
import type {DGFilteringCType, DGSelectionCType} from "./hooks/hooks";
import {DGFiltering, DGSelection, useDGDef} from "./hooks/hooks";
import useRows from "./hooks/useRows";
import RenderRows from "./table/row/RenderRows";
import type {filterType} from "./types";

// const useStyles = makeStyles(() => ({
//     row: {
//         width: "fit-content",
//         "&:hover": {
//             // "&:after": {
//             //     content: "''",
//             //     top: 0,
//             //     left: 0,
//             //     bottom: 0,
//             //     right: 0,
//             //     position: "absolute",
//             //
//             //     backgroundColor: "rgba(0,0,0,0.1)",
//             // },
//             "& .select": {
//                 visibility: "unset !important",
//                 backgroundColor: "#f3f4f6",
//             },
//             "& .index": {
//                 display: "none",
//             },
//         },
//     },
// }));

const DataGridContent = () => {
    const {t} = useTranslation("datagrid");

    const {onAddRow} = useRows();
    const {cols, permission, fullscreen, isOwner} = useDGDef();

    // region states
    // const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize, setPageSize] = useState(20);
    const listRef = useRef(null);
    const [checked, setChecked] = useState<Set<any>>(new Set());
    const [sortedBy, setSortedBy] = useState<string>(""); // Track currently sorted column
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [filters, setFilters] = useState<filterType[]>([]);
    const [textSearch, setTextSearch] = useState("");
    const [sortFlag, setSortFlag] = useState(false);
    const [count, setCount] = useState(0);

    // endregion

    // region column : actions resizeColumn
    // const toggleColumn = (col: DataGridColumn) => {
    //     setVisibleColumns((prevColumns) => {
    //         const updatedColumns = [...prevColumns];
    //         const colIndex = updatedColumns.findIndex((c) => c.id === col.id);
    //         updatedColumns[colIndex].isVisible = !updatedColumns[colIndex].isVisible;
    //         return updatedColumns;
    //     });
    // };

    // const toggleAllColumns = () =>
    //     setVisibleColumns(
    //         cols.map((e) => ({
    //             ...e,
    //             isVisible: false,
    //         }))
    //     );

    // const resizeColumn = (event, index) => {
    //     // if (index === cols.length - 1) return;
    //
    //     const element = event.target.parentElement;
    //     const rect = element.getBoundingClientRect();
    //     const startX = event.clientX;
    //     document.addEventListener("mousemove", moveAt);
    //
    //     function moveAt(event) {
    //         const diff = event.clientX - startX;
    //         const newWidths = [...widths];
    //         newWidths[index] = Math.max(0, rect.width + diff); // Prevent negative widths
    //         setWidths(newWidths);
    //     }
    //
    //     document.addEventListener("mouseup", () => {
    //         document.removeEventListener("mousemove", moveAt);
    //     });
    // };

    // const filteredColumns = useMemo(() => {
    //     return visibleColumns.filter((col) => !col.isVisible);
    // }, [cols, visibleColumns]);
    // endregion

    // region sorting
    const onSort = (colId: string) => {
        const sortField = colId;
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        if (sortField !== sortedBy) {
            setSortFlag(false);
            setSortedBy(sortField);
        } else {
            if (sortFlag) {
                setSortedBy("");
            } else {
                setSortOrder(newSortOrder);
            }
            setSortFlag(true);
        }
    };

    // const sortedData = useMemo(() => {
    //     if (!sortedBy || !sortOrder) return rows;
    //
    //     return rows?.slice().sort((a, b) => {
    //         const sortValueA = a[sortedBy];
    //         const sortValueB = b[sortedBy];
    //
    //         if (typeof sortValueA === "string" && typeof sortValueB === "string") {
    //             return sortOrder === "asc" ? sortValueA.localeCompare(sortValueB) : sortValueB.localeCompare(sortValueA);
    //         } else {
    //             try {
    //                 const sA = JSON.stringify(sortValueA);
    //                 const sB = JSON.stringify(sortValueB);
    //                 return sortOrder === "asc" ? sA.localeCompare(sB) : sB.localeCompare(sA);
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //             return sortOrder === "asc" ? sortValueA - sortValueB : sortValueB - sortValueA;
    //         }
    //     });
    // }, [rows, sortedBy, sortOrder]);
    // endregion

    // region filtering and searching data
    // const onAddFilter = useCallback(() =>
    //     // colId: string,
    //     // type: string,
    //     // value:string,
    //     {
    //         const t = {...filters};
    //
    //         // if (t[colId]) {
    //         //     const i = t[colId].findIndex((tc) => tc.type === type);
    //         //     if (i > -1) {
    //         //         t[colId][i].value = [...t[colId][i].value, value];
    //         //     } else {
    //         //         t[colId] = [...t[colId], filter];
    //         //     }
    //         // } else {
    //         // }
    //         setFilters(t);
    //
    //         // setCurrentPage(1);
    //     }, [filters]);

    const onChangeFilter = (filter: filterType) => {
        const t = [...filters];
        const i = t.findIndex((d) => d.colId === filter.colId);
        if (i > -1) {
            t[i] = filter;
        } else {
            t.push(filter);
        }
        setFilters(t);
        // t[i].value = t[i].value?.filter((v) => !remove.includes(v));
        // t[i].value = [...t[i].value, ...add];
        // setFilters(t);
        // setCurrentPage(1);
    };

    const onRemoveFilter = useCallback(
        (colId: string) =>
            // key: string,
            // value: string
            {
                const t = [...filters];
                const i = t.findIndex((d) => d.colId === colId);
                if (i > -1) {
                    t.splice(i, 1);
                }
                // t[i].value = t[i].value?.filter((v) => v !== value) || [];
                setFilters(t);
                // setCurrentPage(1);
            },
        [filters]
    );

    // const filteredData = useMemo(() => {
    //     return sortedData?.filter((obj) => {
    //         let match = true;
    //
    //         for (const {condition, colId, value} of filters) {
    //             const cellValue = typeof obj[colId] === "string" ? obj[colId] : obj[colId];
    //             // console.log({cellValue});
    //             switch (condition) {
    //                 case "eq":
    //                     if ((typeof value === "string" || typeof value === "number") && typeof value === typeof cellValue)
    //                         match = match && value === cellValue;
    //                     else if (_.isArray(value))
    //                         match =
    //                             match &&
    //                             value.some((v) => {
    //                                 if ((typeof v === "string" || typeof v === "number") && typeof v === typeof cellValue) return v === cellValue;
    //                                 else if (!!v.id && cellValue) return v.id === cellValue.id;
    //                                 else if (_.isArray(cellValue)) {
    //                                     return cellValue.some((v2) => {
    //                                         if ((typeof v2 === "string" || typeof v2 === "number") && typeof v2 === typeof v) return v2 === v;
    //                                         else if (!!v2.id && v) return v2.id === v.id;
    //                                         else return false;
    //                                     });
    //                                 } else return false;
    //                             });
    //                     else if (!cellValue) return false;
    //                     break;
    //                 case "contains":
    //                     // match = match && obj[key].some((v) => value.includes(v))
    //                     // console.log({objkey: obj[key], value, isArray: isArray(obj[key])});
    //
    //                     // if (typeof obj[colId] === "string") match = match && filter.values.includes(obj[colId]);
    //                     // else if (isArray(obj[colId]))
    //                     if (typeof value === "string") {
    //                         match = match && cellValue?.includes(value);
    //                     }
    //
    //                     if (!obj[colId] && !value.includes("empty")) match = match && false;
    //                     else if (obj[colId] && !value.includes("empty")) match = match && true;
    //                     break;
    //                 case "option":
    //                     if (_.isArray(value)) {
    //                         match =
    //                             match &&
    //                             value.some((v) => {
    //                                 if (obj[colId]) return obj[colId].includes(v);
    //                                 else return false;
    //                             });
    //                     } else if (!cellValue) return false;
    //                     break;
    //
    //                 case "gt":
    //                     match = match && parseFloat(cellValue) > +value;
    //                     break;
    //                 case "lt":
    //                     match = match && parseFloat(cellValue) < +value;
    //                     break;
    //                 case "neq":
    //                     match = match && cellValue !== value;
    //                     break;
    //                 case "between":
    //                     console.log(value);
    //                     match = match && parseFloat(cellValue) >= +value.from && parseFloat(cellValue) <= +value.to;
    //                     break;
    //                 case "startsWith":
    //                     match = match && cellValue?.startsWith(value);
    //                     break;
    //                 case "endsWith":
    //                     match = match && cellValue?.endsWith(value);
    //                     break;
    //                 case "empty":
    //                     match = match && !obj[colId];
    //                     break;
    //                 case "notEmpty":
    //                     match = match && !!obj[colId];
    //                     break;
    //                 case "same":
    //                     match = match && isSameDay(cellValue, value.dateFrom);
    //                     break;
    //                 case "before":
    //                     match = match && cellValue < value.dateFrom;
    //                     break;
    //                 case "after":
    //                     match = match && cellValue > value.dateFrom;
    //                     break;
    //                 case "range":
    //                     if (!value.dateTo) break;
    //                     match = match && cellValue >= value.dateFrom && cellValue <= value.dateTo;
    //                     break;
    //
    //                 // case "number":
    //                 //     match =
    //                 //         match &&
    //                 //         value.some((v) => {
    //                 //             if (+cellValue > JSON.parse(v).min && +cellValue < JSON.parse(v).max) {
    //                 //                 return true;
    //                 //             } else return false;
    //                 //         });
    //                 //     break;
    //                 // case "size":
    //                 //     match =
    //                 //         match &&
    //                 //         value.some((v) => {
    //                 //             if (v === "-1") {
    //                 //                 if (+itemValue > getDataSizes[-1]) return true;
    //                 //             } else if (v === "0") {
    //                 //                 if (+itemValue < getDataSizes[0]) return true;
    //                 //             } else {
    //                 //                 if (+itemValue > getDataSizes[+v - 1] && +itemValue < getDataSizes[+v]) return true;
    //                 //             }
    //                 //             return false;
    //                 //         });
    //                 //     break;
    //                 // case "selectedDate":
    //                 //     match =
    //                 //         match &&
    //                 //         value.some((v) => {
    //                 //             const parsedV = JSON.parse(v);
    //                 //             const isDay = !!parsedV.day && parsedV.day > 0;
    //                 //             const isMonth = !isDay && parsedV.month > -1;
    //                 //             const isYear = !isMonth && !isDay;
    //                 //             if (isYear) {
    //                 //                 if (isSameYear(new Date(+cellValue), new Date(parsedV.year + ""))) return true;
    //                 //             } else if (isMonth) {
    //                 //                 if (isSameMonth(new Date(+cellValue), new Date(parsedV.year, parsedV.month))) return true;
    //                 //             } else if (isDay) {
    //                 //                 if (isSameDay(new Date(+cellValue), new Date(parsedV.year, parsedV.month, parsedV.day))) return true;
    //                 //             }
    //                 //             return false;
    //                 //         });
    //                 //
    //                 //     break;
    //                 default:
    //                     break;
    //             }
    //
    //             // Exit early if no match found
    //             if (!match) return false;
    //         }
    //         if (textSearch.trim() !== "") {
    //             match = match && searchInObject(obj, textSearch);
    //         }
    //
    //         return match;
    //     });
    // }, [sortedData, filters, textSearch]);
    // endregion

    // region Pagination
    // const paginatedData = useMemo(() => {
    //     const startIndex = (currentPage - 1) * pageSize;
    //     const endIndex = startIndex + pageSize;
    //     return filteredData?.slice(startIndex, endIndex);
    // }, [currentPage, pageSize, filteredData]);

    // const totalPages = sortedData ? Math.ceil(sortedData.length / pageSize) : 0;

    // const filterData = ({size, page}: SearchType) => {
    //     if (size) {
    //         setPageSize(size);
    //     }
    //     if (page) {
    //         if (page > 0 && page <= totalPages) {
    //             setCurrentPage(page);
    //         }
    //     }
    // };
    // endregion

    // region Selection
    const toggleRow = (rowId: string, isChecked: boolean) => {
        const t = new Set(checked);
        if (isChecked) {
            t.add(rowId);
        } else {
            t.delete(rowId);
        }
        setChecked(t);
    };

    const toggleAll = (isChecked: boolean) => {
        if (isChecked) {
            // setChecked(new Set(filteredData.map((s) => s.id)));
        } else {
            setChecked(new Set());
        }
    };
    // endregion

    const filtersValue: DGFilteringCType = {
        sortedBy,
        sortOrder,
        textSearch,
        filters,
        onSort,
        onChangeFilter,
        onRemoveFilter,
        setFilters,
        setTextSearch,
        setCount,
        count,
    };

    const selectionValue: DGSelectionCType = {
        checked,
        toggleRow,
        toggleAll,
    };

    const onClickAdd = () => {
        setFilters([]);
        setSortedBy("");
        setSortOrder("asc");
        onAddRow().then(() => {
            // @ts-ignore
            listRef.current?.scrollToIndex?.({index: count});
        });
    };

    // const whiteColor = "#ffffff";

    const innerRender = () => (
        <>
            <DGSettings onClickAdd={onClickAdd} />

            <ShowFilters
                // resultLength={filteredData ? filteredData.length : 0}
                textSearch={textSearch}
                setTextSearch={setTextSearch}
            />

            <div className={"border-1 bg-white border-gray-200 overflow-x-hidden"}>
                {cols.length > 0 ? (
                    <>
                        <div className="relative " id={"datagrid-inner"}>
                            {/*<ViewportList*/}
                            {/*    scrollThreshold={50}*/}
                            {/*    viewportRef={ref}*/}
                            {/*    initialIndex={0}*/}
                            {/*    initialPrerender={50}*/}
                            {/*    items={filteredData}*/}
                            {/*    overscan={10}>*/}
                            {/*    {(row, index) => (*/}
                            {/*        <div*/}
                            {/*            key={row.id}*/}
                            {/*            style={{*/}
                            {/*                backgroundColor: row.color && row.color.hex !== whiteColor && row.color.hex,*/}
                            {/*            }}*/}
                            {/*            className={clsx(*/}
                            {/*                classes.row,*/}
                            {/*                checked.has(row.id) && "bg-blue-lightest",*/}
                            {/*                "flex relative",*/}
                            {/*                focusedEdit ? "" : " hover:bg-gray-100 hover:opacity-80 hover-overflow",*/}
                            {/*                rowsConf.modified.map((x) => x.id).includes(row.id) && "bg-orange-lightest hover:bg-orange-lighter"*/}
                            {/*            )}>*/}
                            {/*            <div*/}
                            {/*                style={{*/}
                            {/*                    backgroundColor: row.color && row.color.hex !== whiteColor && row.color.hex,*/}
                            {/*                }}*/}
                            {/*                className={clsx(checked.has(row.id) ? "bg-blue-lightest" : "bg-white", "sticky z-9999 left-0 flex")}>*/}
                            {/*                {((permission !== "readonly" && permission !== "disabled") || isOwner) && (*/}
                            {/*                    <div className="w-28 border-r-1 border-b-1 border-gray-200">*/}
                            {/*                        {focusedEdit === "" && (*/}
                            {/*                            <div className={"absolute top-0 right-0 bottom-0 left-0 invisible select flex items-center "}>*/}
                            {/*                                /!*<EditRow index={rowInd} row={row} />*!/*/}
                            {/*                            </div>*/}
                            {/*                        )}*/}
                            {/*                    </div>*/}
                            {/*                )}*/}
                            {/*                /!*region checkbox *!/*/}
                            {/*                /!*{selectable && (*!/*/}
                            {/*                <div*/}
                            {/*                    className={*/}
                            {/*                        " min-w-32 text-center content-center border-b-1 align-middle text-gray-400 w-32  relative border-r-1 border-gray-200"*/}
                            {/*                    }>*/}
                            {/*                    /!*<div className={clsx("p-0 absolute top-0 right-0 bottom-0 left-0 select w-full flex items-center")}>*!/*/}
                            {/*                    /!*    <Checkbox*!/*/}
                            {/*                    /!*        size={"small"}*!/*/}
                            {/*                    /!*        className={clsx(!checked.has(row.id) && "invisible", "p-0 absolute top-0 right-0 bottom-0 left-0 select")}*!/*/}
                            {/*                    /!*        checked={checked.has(row.id)}*!/*/}
                            {/*                    /!*        onChange={(e) => toggleRow(row.id, e.target.checked)}*!/*/}
                            {/*                    /!*    />*!/*/}
                            {/*                    /!*</div>*!/*/}

                            {/*                    <div*/}
                            {/*                        className={clsx(checked.has(row.id) && "hidden", focusedEdit === "" && "index", " align-middle")}>*/}
                            {/*                        {index + 1}*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                /!*)}*!/*/}
                            {/*            </div>*/}

                            {/*            /!* endregion *!/*/}
                            {/*            {cols.map(*/}
                            {/*                (col) =>*/}
                            {/*                    !col.hidden && (*/}
                            {/*                        <div*/}
                            {/*                            key={col.id}*/}
                            {/*                            style={{*/}
                            {/*                                width: col.minWidth || colDefaultMinWidth,*/}
                            {/*                                // width: col.fixedWidth ? col.fixedWidth : col.minWidth,*/}
                            {/*                                borderLeftWidth: 5,*/}
                            {/*                                borderRightWidth:*/}
                            {/*                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor*/}
                            {/*                                        ? 5*/}
                            {/*                                        : 1,*/}
                            {/*                                borderRightColor:*/}
                            {/*                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor*/}
                            {/*                                        ? col.color.hex*/}
                            {/*                                        : "inherit",*/}
                            {/*                                borderLeftColor:*/}
                            {/*                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor*/}
                            {/*                                        ? col.color.hex*/}
                            {/*                                        : "transparent",*/}
                            {/*                                backgroundColor:*/}
                            {/*                                    row.color && row.color.hex !== whiteColor*/}
                            {/*                                        ? ""*/}
                            {/*                                        : col.color && col.color.hex !== whiteColor*/}
                            {/*                                        ? col.color.hex*/}
                            {/*                                        : "",*/}
                            {/*                            }}*/}
                            {/*                            onClick={(e) => {*/}
                            {/*                                if (!(col.viewType === "checkbox") && isCellEditable(col.id)) {*/}
                            {/*                                    e.stopPropagation();*/}
                            {/*                                    setFocusedEdit(row.id + "-" + col.id);*/}
                            {/*                                }*/}
                            {/*                            }}*/}
                            {/*                            className={clsx(*/}
                            {/*                                "min-h-24 flex-shrink-0 min-w-52  border-b-1 flex items-center whitespace-nowrap  relative",*/}
                            {/*                                col.className,*/}
                            {/*                                col.id === hoveredCol && "opacity-80 bg-gray-100",*/}
                            {/*                                focusedEdit !== row.id + "-" + col.id && "overflow-hidden"*/}
                            {/*                            )}>*/}
                            {/*                            <DGCell*/}
                            {/*                                setFocusedEdit={setFocusedEdit}*/}
                            {/*                                col={col}*/}
                            {/*                                row={row}*/}
                            {/*                                focusedEdit={focusedEdit === row.id + "-" + col.id}*/}
                            {/*                            />*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</ViewportList>*/}

                            <RenderRows
                                // @ts-ignore
                                ref={listRef}
                            />

                            {!((permission === "readonly" || permission === "disabled") && !isOwner) && (
                                <div className={"border-r border-t inline-block border-gray-200 border-solid"}>
                                    <Button startIcon={<Icon fontSize={"small"}>add</Icon>} onClick={onClickAdd}>
                                        {t("addRow")}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <EmptyView noIcon label={t("noColumns")} />
                )}

                <DGFooter
                // filterData={filterData}
                // pageSize={pageSize}
                // currentPage={currentPage}
                // checked={checked}
                // totalPages={totalPages}
                // filteredData={filteredData}
                />
            </div>
        </>
    );

    return (
        <DGFiltering.Provider value={filtersValue}>
            <DGSelection.Provider value={selectionValue}>
                {fullscreen ? (
                    <Dialog fullScreen open={true}>
                        {innerRender()}
                    </Dialog>
                ) : (
                    innerRender()
                )}
                <></>
            </DGSelection.Provider>
        </DGFiltering.Provider>
    );
};

export default DataGridContent;
