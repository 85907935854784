import {createGenerateClassName, jssPreset, StylesProvider} from "@material-ui/styles";
import AppContext from "app/RouteContext";
import {create} from "jss";
import jssPluginExtend from "jss-plugin-extend";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import {Suspense} from "react";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import FuseLoading from "../@fuse/components/FuseLoading/FuseLoading";
import history from "../@history";
import routes from "./configs/routesConfig";
import store from "./store";
import {AuthProvider} from "./auth/AuthProvider";
import "../i18n";
import {LocalizeProvider} from "react-localize-redux";
import {SocketProvider} from "@hooks";
import {SWRConfig} from "swr";
import {swrFetcher} from "@api";
import {ConfirmProvider} from "material-ui-confirm";
import {confirmDefaultOptions} from "../utils/variables";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {FuseTheme} from "../@fuse";
import AppContent from "./AppContent";

const jssInsert = document.getElementById("jss-insertion-point");

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssPluginExtend()],
    insertionPoint: jssInsert || undefined,
});

const generateClassName = createGenerateClassName();

const App = () => {
    return (
        <Suspense fallback={<FuseLoading />}>
            <Router history={history}>
                <AppContext.Provider value={{routes}}>
                    <Provider store={store}>
                        <AuthProvider>
                            <StylesProvider jss={jss} generateClassName={generateClassName}>
                                {/*<I18nextProvider i18n={i18n} defaultNS={["_", "errors", "profile", "login", "nav", "titles"]}>*/}
                                <LocalizeProvider>
                                    <SocketProvider>
                                        <SWRConfig
                                            value={{
                                                refreshInterval: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? 0 : 600000,
                                                fetcher: swrFetcher,
                                                revalidateOnMount: false,
                                                onErrorRetry: (error) => {
                                                    if (error.status === 400) {
                                                        //noinspection UnnecessaryReturnStatementJS
                                                        return;
                                                    }
                                                },
                                                // dedupingInterval: 0,
                                                revalidateOnFocus: !(!process.env.NODE_ENV || process.env.NODE_ENV === "development"),
                                                // revalidateOnFocus: true,
                                            }}>
                                            <FuseTheme>
                                                {/*// @ts-ignore*/}
                                                <ConfirmProvider defaultOptions={confirmDefaultOptions}>
                                                    {/*// @ts-ignore*/}
                                                    <DndProvider backend={HTML5Backend}>
                                                        <AppContent />
                                                    </DndProvider>
                                                </ConfirmProvider>
                                            </FuseTheme>
                                        </SWRConfig>
                                    </SocketProvider>
                                </LocalizeProvider>
                                {/*</I18nextProvider>*/}
                            </StylesProvider>
                        </AuthProvider>
                    </Provider>
                </AppContext.Provider>
            </Router>
        </Suspense>
    );
};
//
if (window.Cypress) {
    // @ts-ignore cypress
    window.store = store;
}

export default App;
