
import {DGDefContext} from "../hooks/hooks";
import type {DGColType, DGRowType} from "../types";

export const DataGridReadonly = (props: {id: string; defaultCols?: DGColType[]; defaultRows?: DGRowType[]}) => {
    return (
        <DGDefContext.Provider
            value={{
                id: props.id,
                cols: props.defaultCols ? props.defaultCols : [],
                colsWidth: props.defaultCols ? props.defaultCols.reduce((acc, value) => ({...acc, [value.id]: value.minWidth}), {}) : {},
                permission: "readonly",
                title: "",
                isOwner: false,
                // description: "",
                onPublishSettings: () => {},
                onChangeSetting: () => {},
                onAddCol: () => {},
                onDeleteCol: () => {},
                onChangeCol: () => {},
                fullscreen: false,
            }}>
            {/*<DataGridColsProvider {...props} />*/}
        </DGDefContext.Provider>
    );
};
