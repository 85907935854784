import {lazy} from "react";

export const cmbTransactionDataConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/transactions/cmb/print",
        },
        {
            path     : "/transactions/:accountId/cmb",
            component: lazy(() => import("./CMBTransactionData"))
        },
        {
            path     : "/transactions/cmb",
            component: lazy(() => import("./CMBTransactionData"))
        }
    ]
};
