import {turnOff} from "@fuseActions";
import {Drawer} from "@material-ui/core";
import {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import FavoritePanel from "../../../../components/layout/fav/FavoritePanel";
import VersionUpdateConfirm from "../../../../utils/CheckUpdateHelper/VersionUpdateConfirm";
import CRMDialogs from "../../../components/CommonDialogs";
import CommonMixinsView from "../../../components/CommonMixinsView";
import QuickPanel from "../../../notifications/QuickPanel";

function LoggedInComponents() {
    const favDialog = useSelector(({fuse}) => fuse.dialogs["favorites"]);
    const dispatch = useDispatch();

    return (
        <>
            <QuickPanel />
            <CRMDialogs />
            <CommonMixinsView />
            <Drawer
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                anchor="top"
                open={!!favDialog}
                onClose={() => dispatch(turnOff("favorites"))}>
                <FavoritePanel />
            </Drawer>
            <VersionUpdateConfirm />
        </>
    );
}

export default memo(LoggedInComponents);
