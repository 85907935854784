import {createContext, useContext} from "react";
import type {SearchType} from "../../../../types";

export type FiltersContextType = {
    filters: SearchType;
    addFilter: (filter: SearchType) => void;
    removeFilter: (filter: string | string[]) => void;
    clearFilters: () => void;
    replaceFilters: (filters: SearchType) => void;
    hasFilters: boolean;
    totalPages: number;
    totalElements: number;
};

export const FiltersContext = createContext<FiltersContextType>({
    filters: {},
    addFilter: () => {},
    removeFilter: () => {},
    clearFilters: () => {},
    replaceFilters: () => {},
    hasFilters: false,
    totalPages: 0,
    totalElements: 0,
});

export function useFilters() {
    return useContext(FiltersContext);
}
