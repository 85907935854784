import {Icon, IconButton} from "@material-ui/core";
import type {FC, ReactNode} from "react";
import useSWR from "swr";
import FaveButton from "../../layout/fav/FaveButton";
import {useFilters} from "./hooks/filtersContext";
import {useMainContext} from "./hooks/mainContext";

export type HeaderProps = {
    title: string;
    total?: number;
    subheader?: ReactNode;
    selector?: ReactNode;
    actions?: ReactNode;
};

const Header: FC<HeaderProps> = ({title, actions, selector, subheader}) => {
    const {link} = useMainContext();
    const {filters, totalElements} = useFilters();
    const {mutate, isValidating} = useSWR([link, filters]);
    return (
        <div className={"p-12 flex items-center w-full justify-between"}>
            <div className="flex  items-center">
                <div className={"md:flex items-center"}>
                    <div>
                        <div className={"md:text-32 text-24 font-bold mr-20"}>
                            {title}
                            {!!totalElements && <small className={"mx-3 text-grey"}>({totalElements})</small>}

                            <IconButton onClick={mutate} className={isValidating ? "animate-spin" : ""}>
                                <Icon>refresh</Icon>
                            </IconButton>

                            <FaveButton sourceId={location.pathname} title={title} type={"link"} />
                        </div>
                        {subheader && subheader}
                    </div>
                    {selector && selector}
                </div>
            </div>

            <div className="flex flex-1 items-center mt-12 md:mt-0 justify-end pr-0 sm:pl-12 ">{actions && actions}</div>
        </div>
    );
};

export default Header;
