import {useEffect, useState} from 'react';
import {useLocaleStore, useMUIDialog} from '../../../../../@fuse/hooks';
import {GDialogRegistries} from '@fuse/state/constant';
import {useMemoizedFn} from 'ahooks';
import {Dialog, DialogActions, Paper, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {Translate} from 'react-localize-redux';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import {nameText} from 'utils/NameTooltipComponent';
import useCommercial from '../../store/state/useCommercial';

// eslint-disable-next-line no-empty-pattern
function SheetPreViewDialog({}) {
    const {userPay} = useCommercial();

    // @ts-ignore
    const {dialogProps, onTurnOffDialog} = useMUIDialog({
        registry: GDialogRegistries.sheetPreViewDialog,
        maxWidth: "md",
    });

    const {language} = useLocaleStore();

    const [cols, setCols] = useState<string[]>([""]);

    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        language === "en"
            ? setCols(["User", "Price", "StageName", "ProjectId", "projectName"])
            : setCols(["用户", "价格", "阶段名", "项目号", "项目名"]);
        const d = userPay;
        const ttt = d?.items?.map((_) => [nameText(d?.user), _?.price, _?.stageName, _?.projectId, _?.projectName]);
        setRows(ttt);
    }, [language, userPay]);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    return (
        <>
            <Dialog {...dialogProps}>
                <TableContainer className={"w-full p-3"} component={Paper}>
                    <Table className={"w-full"}>
                        <TableHead>
                            <TableRow>
                                {cols.map((col, index) => (
                                    <TableCell key={index}>{col}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    {row.map((item, index) => (
                                        <TableCell key={`${item}-${index}`} component="th" scope="row">
                                            {item}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {<Translate id={"_.close"} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SheetPreViewDialog;
