import {Typography} from '@material-ui/core';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';
import {useMemo} from 'react';
import {userType} from '../../types';

type Props = {
    user: userType;
    strongTag?: boolean;
    className?: string;
};

function isLikelyChinese(name) {
    // Unicode block ranges for common CJK characters (Chinese, Japanese, Korean)
    const chineseRegex = /[\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF]/;
    return chineseRegex.test(name);
}

function isLikelyEnglish(name) {
    // Check for letters a-z and A-Z (basic English characters)
    const englishRegex = /[a-zA-Z]/;
    return englishRegex.test(name) && !isLikelyChinese(name); // Exclude mixed names
}

// const name = "王小明"; // Chinese name
// console.log(isLikelyChinese(name)); // true
//
// const name2 = "John Smith"; // English name
// console.log(isLikelyEnglish(name2)); // true
//
// const name3 = "李明 Smith"; // Mixed name (excluded)
// console.log(isLikelyEnglish(name3)); // false

export function NameLabel(props: Props & TypographyProps) {
    const {user, strongTag, className, ...rest} = props;

    const firstName = useMemo(() => (user ? user.firstName : ""), [user]);
    const lastName = useMemo(() => (user ? user.lastName : ""), [user]);

    // const regx =
    //     /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/;

    return (
        <Typography
            component={strongTag ? "strong" : "span"}
            className={classNames("truncate font-black text-black", className)}
            {...rest}
        >
            {!isLikelyEnglish(firstName) ? `${lastName}${firstName}` : `${firstName} ${lastName}`}
            {/*{regx.test(`${firstName}${lastName}`) ? `${lastName}${firstName}` : `${firstName} ${lastName}`}*/}
        </Typography>
    );
}


