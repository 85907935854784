import {turnOn} from "@fuseActions";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import {makeStyles} from "@material-ui/styles";
import {DialogWrapper, useDGFilters} from "@ui";
import clsx from "clsx";
import {isSameDay} from "date-fns";
import {useLiveQuery} from "dexie-react-hooks";
import {isArray} from "lodash";
import type {FC} from "react";
import {forwardRef, useRef, useState} from "react";
import {TwitterPicker} from "react-color";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ViewportList} from "react-viewport-list";
import {FuseSuspense} from "../../../../../@fuse";
import {db} from "../../db";
import {useDg, useDGDef, useDGSelection} from "../../hooks/hooks";
import useRows from "../../hooks/useRows";
import type {DGRowType} from "../../types";
import {colDefaultMinWidth} from "../../types";
import DGCell from "../cell/DGCell";
import THList from "../header/THList";
import EditRecord from "./EditRecord";

const useStyles = makeStyles(() => ({
    row: {
        width: "fit-content",
        "&:hover": {
            // "&:after": {
            //     content: "''",
            //     top: 0,
            //     left: 0,
            //     bottom: 0,
            //     right: 0,
            //     position: "absolute",
            //
            //     backgroundColor: "rgba(0,0,0,0.1)",
            // },
            "& .select": {
                visibility: "unset !important",
                backgroundColor: "#f3f4f6",
            },
            "& .indexWrapper": {
                backgroundColor: "#f3f4f6",
            },
            // "& .index": {
            //     display: "none",
            // },
        },
    },
}));

const validColIds = [
    "c0",
    "c1",
    "c2",
    "c3",
    "c4",
    "c5",
    "c6",
    "c7",
    "c8",
    "c9",
    "c10",
    "c11",
    "c12",
    "c13",
    "c14",
    "c15",
    "c16",
    "c17",
    "c18",
    "c19",
    "c20",
    "c21",
    "c22",
    "c23",
    "c24",
    "c25",
    "c26",
    "c27",
    "c28",
    "c29",
    "c30",
    "c31",
    "c32",
    "c33",
    "c34",
    "c35",
    "c36",
    "c37",
    "c38",
    "c39",
    "c40",
    "c41",
    "c42",
    "c43",
    "c44",
    "c45",
    "c46",
    "c47",
    "c48",
    "c49",
    "c50",
    "c51",
    "c52",
    "c53",
    "c54",
    "c55",
    "c56",
    "c57",
    "c58",
    "c59",
    "c60",
    "c61",
    "c62",
    "c63",
    "c64",
    "c65",
    "c66",
    "c67",
    "c68",
    "c69",
    "c70",
    "c71",
    "c72",
    "c73",
    "c74",
    "c75",
    "c76",
    "c77",
    "c78",
    "c79",
    "c80",
    "c81",
    "c82",
    "c83",
    "c84",
    "c85",
    "c86",
    "c87",
    "c88",
    "c89",
    "c90",
    "c91",
    "c92",
    "c93",
    "c94",
    "c95",
    "c96",
    "c97",
    "c98",
    "c99",
];

const RenderRows: FC = forwardRef((props, ref) => {
    // region hooks
    const {t} = useTranslation(["datagrid", "_"]);
    const {permission, isOwner, cols, id, colsWidth, tempColsWidth, hiddenCols, fullscreen} = useDGDef();
    const {sortedBy, sortOrder, filters, setCount} = useDGFilters();
    const {isCellEditable} = useDg();
    const {toggleRow} = useDGSelection();
    const {onChangeRow, onDeleteRow} = useRows();
    const dispatch = useDispatch();
    const viewportRef = useRef(null);
    const classes = useStyles();
    // endregion

    // region states
    const whiteColor = "#ffffff";
    const limitPagination = 10000;
    const [limit, setLimit] = useState(limitPagination);
    const [el, setEl] = useState<HTMLButtonElement | null>(null);
    const [colorEl, setColorEl] = useState(null);
    const [focusedEdit, setFocusedEdit] = useState("");
    const [selectRow, setSelectRow] = useState<DGRowType>();
    // endregion

    const rows = useLiveQuery(() => {
        let query = db.dataGridRows.orderBy(sortedBy ? (validColIds.includes(sortedBy) ? sortedBy : "") : "");

        if (sortOrder === "desc") {
            query = query.reverse();
        }
        query = query.filter((x) => x.dataGridId === id);

        if (filters.length > 0) {
            for (const {condition, colId, value} of filters) {
                switch (condition) {
                    case "eq":
                        query = query.and((x) => x[colId] === value);
                        break;
                    case "contains":
                        query = query.and((x) => x[colId]?.includes(value));
                        break;
                    case "gt":
                        query = query.and((x) => parseFloat(x[colId]) > +value);
                        break;
                    case "lt":
                        query = query.and((x) => parseFloat(x[colId]) < +value);
                        break;
                    case "neq":
                        query = query.and((x) => x[colId] !== value);
                        break;
                    case "between":
                        query = query.and((x) => parseFloat(x[colId]) >= +value.from && parseFloat(x[colId]) <= +value.to);
                        break;
                    case "startsWith":
                        query = query.and((x) => x[colId]?.startsWith(value));
                        break;
                    case "endsWith":
                        query = query.and((x) => x[colId]?.endsWith(value));
                        break;
                    case "empty":
                        query = query.and((x) => !x[colId]);
                        break;
                    case "notEmpty":
                        query = query.and((x) => !!x[colId]);
                        break;
                    case "same":
                        query = query.and((x) => isSameDay(x[colId], value.dateFrom));
                        break;
                    case "before":
                        query = query.and((x) => x[colId] < value.dateFrom);
                        break;
                    case "after":
                        query = query.and((x) => x[colId] > value.dateFrom);
                        break;
                    case "range":
                        if (value.dateTo) {
                            // @ts-ignore
                            query = query.and((x) => x[colId] >= value.dateFrom && x[colId] <= value.dateTo);
                        }
                        break;
                    case "option":
                        if (isArray(value)) {
                            query = query.and((x) =>
                                value.some((v) => {
                                    if (x[colId]) return x[colId].includes(v);
                                    else return false;
                                })
                            );
                        } else {
                            query = query.and((x) => !x[colId]);
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        query.count().then((x) => setCount(x));

        return query.limit(limit).toArray();
    }, [limit, sortedBy, sortOrder, filters]);

    return (
        <FuseSuspense>
            {selectRow && (
                <>
                    <DialogWrapper name={"editDGRow-" + selectRow.id} header={t("editRow")}>
                        {(onClose) => <EditRecord row={selectRow} onClose={onClose} />}
                    </DialogWrapper>

                    <Menu open={Boolean(el)} anchorEl={el} onClose={() => setEl(null)}>
                        <MenuItem
                            onClick={() => {
                                dispatch(turnOn("editDGRow-" + selectRow.id));
                                setEl(null);
                            }}>
                            <div className={"min-w-32 -ml-12 mr-8  flex items-center justify-center"}>
                                <Icon className={"text-gray-500"} fontSize={"small"} children={"edit"} />
                            </div>
                            {t("editData")}
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                // @ts-ignore
                                setColorEl(event.currentTarget);
                            }}>
                            <div className={"min-w-32 -ml-12 mr-8 flex items-center justify-center"}>
                                <div className={"w-16 h-16 rounded-4 bg-gray-50"} style={{backgroundColor: selectRow.color?.hex}}></div>
                            </div>
                            {t("rowColor")}
                            <Icon className={"ml-8 -mr-8 text-gray-400"}>chevron_right</Icon>
                        </MenuItem>
                        <MenuItem
                            className={"text-red"}
                            onClick={() => {
                                toggleRow(selectRow.id, false);
                                setEl(null);
                                onDeleteRow(selectRow.id);
                            }}>
                            <div className={"min-w-32 -ml-12 mr-8  flex items-center justify-center"}>
                                <Icon fontSize={"small"}>delete_forever</Icon>
                            </div>
                            {t("delete")}
                        </MenuItem>
                    </Menu>

                    <Popover
                        open={Boolean(colorEl)}
                        anchorEl={colorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={() => setColorEl(null)}>
                        <TwitterPicker
                            colors={[
                                "#999999",
                                "#f3f4f6",
                                "#FFFFFF",
                                "#D9B3A9",
                                "#FFC266",
                                "#FFE566",
                                "#CCEEFF",
                                "#D9F2FF",
                                "#66C9FF",
                                "#ffa1bd",
                                "#FFD9E5",
                                "#E5C2FF",
                            ]}
                            className={"shadow-0 mr-12"}
                            color={selectRow.color}
                            triangle={"hide"}
                            width={320}
                            onChangeComplete={(color) => {
                                onChangeRow({...selectRow, color: color});
                                setEl(null);
                                setColorEl(null);
                            }}
                        />
                    </Popover>
                </>
            )}

            <div
                ref={viewportRef}
                className={"scroll-container overflow-scroll"}
                style={{maxHeight: `calc(100vh - ${fullscreen ? 190 : 300}px)`}}
                // style={{maxHeight: 200}}
            >
                <THList />
                <ViewportList
                    // @ts-ignore
                    ref={ref}
                    onViewportIndexesChange={(indexes) => {
                        if (indexes[1] > limit - limitPagination) setLimit((x) => x + limitPagination);
                    }}
                    viewportRef={viewportRef}
                    scrollThreshold={30}
                    items={rows}
                    initialPrerender={30}
                    overscan={30}
                    initialIndex={0}>
                    {(row, index) => (
                        <div
                            key={row.id}
                            style={{
                                backgroundColor: row.color && row.color.hex !== whiteColor && row.color.hex,
                            }}
                            className={clsx(
                                classes.row,
                                // checked.has(row.id) && "bg-blue-lightest",
                                "flex",
                                focusedEdit ? "" : " hover:bg-gray-100 hover:opacity-80 hover-overflow",
                                row.activeTime && "bg-orange-lightest hover:bg-orange-lighter"
                            )}>
                            <div
                                style={{
                                    backgroundColor: row.color && row.color.hex !== whiteColor && row.color.hex,
                                }}
                                className={clsx(
                                    // checked.has(row.id) ? "bg-blue-lightest" : "bg-white",
                                    "sticky z-999 left-0 flex"
                                )}>
                                {((permission !== "readonly" && permission !== "disabled") || isOwner) && (
                                    <div className="w-28 border-r-1 border-b-1 border-gray-200 bg-white">
                                        {focusedEdit === "" && (
                                            <div className={"absolute top-0 right-0 bottom-0 left-0 invisible select flex items-center "}>
                                                <IconButton
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectRow(row);
                                                        setEl(event.currentTarget);
                                                    }}
                                                    className={"p-0"}
                                                    size={"small"}>
                                                    <Icon children="edit_note" />
                                                </IconButton>
                                                {/*<EditRow index={rowInd} row={row} />*/}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {/*region checkbox */}
                                {/*{selectable && (*/}
                                <div
                                    className={
                                        " min-w-32 text-center  content-center border-b-1 indexWrapper  bg-white align-middle text-gray-400 w-32  relative border-r-1 border-gray-200"
                                    }>
                                    {/*<div className={clsx("p-0 absolute top-0 right-0 bottom-0 left-0 select w-full flex items-center")}>*/}
                                    {/*    <Checkbox*/}
                                    {/*        size={"small"}*/}
                                    {/*        className={clsx(!checked.has(row.id) && "invisible", "p-0 absolute top-0 right-0 bottom-0 left-0 select")}*/}
                                    {/*        checked={checked.has(row.id)}*/}
                                    {/*        onChange={(e) => toggleRow(row.id, e.target.checked)}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <div
                                        className={clsx(
                                            // checked.has(row.id) && "hidden",
                                            focusedEdit === "" && "index",
                                            " align-middle"
                                        )}>
                                        {index + 1}
                                    </div>
                                </div>
                                {/*)}*/}
                            </div>

                            {/* endregion */}
                            {cols.map(
                                (col, i) =>
                                    !hiddenCols?.has(col.id) &&
                                    !col.deleted && (
                                        <div
                                            key={col.id}
                                            style={{
                                                width:
                                                    tempColsWidth && tempColsWidth[col.id]
                                                        ? tempColsWidth[col.id]
                                                        : colsWidth[col.id]
                                                        ? colsWidth[col.id]
                                                        : colDefaultMinWidth,
                                                // width: col.fixedWidth ? col.fixedWidth : col.minWidth,
                                                borderLeftWidth: 5,
                                                borderRightWidth:
                                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor ? 5 : 1,
                                                borderRightColor:
                                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor
                                                        ? col.color.hex
                                                        : "inherit",
                                                borderLeftColor:
                                                    row.color && row.color.hex !== whiteColor && col.color && col.color.hex !== whiteColor
                                                        ? col.color.hex
                                                        : "transparent",
                                                backgroundColor:
                                                    row.color && row.color.hex !== whiteColor
                                                        ? ""
                                                        : col.color && col.color.hex !== whiteColor
                                                        ? col.color.hex
                                                        : "",
                                            }}
                                            onClick={(e) => {
                                                if (!(col.viewType === "checkbox") && isCellEditable(col.id)) {
                                                    e.stopPropagation();
                                                    setFocusedEdit(row.id + "-" + col.id);
                                                }
                                            }}
                                            className={clsx(
                                                "min-h-24 flex-shrink-0 min-w-52  border-b-1 flex items-center whitespace-nowrap  relative",
                                                col.className,
                                                // col.id === hoveredCol && "opacity-80 bg-gray-100",
                                                focusedEdit !== row.id + "-" + col.id && "overflow-hidden"
                                            )}>
                                            <DGCell
                                                setFocusedEdit={setFocusedEdit}
                                                nextFocus={row.id + "-" + cols?.[i + 1]?.id}
                                                col={col}
                                                row={row}
                                                focusedEdit={focusedEdit === row.id + "-" + col.id}
                                            />
                                        </div>
                                    )
                            )}
                        </div>
                    )}
                </ViewportList>
            </div>
        </FuseSuspense>
    );
});

RenderRows.displayName = "RenderRows";

export default RenderRows;
