import type {ReactNode} from "react";
import {createContext, useContext, useState} from "react";
import {logoutUser, setUserData} from "./store/actions";
import {useDispatch} from "react-redux";
import axios, {userPrefix} from "@api";
import type {userType} from "../../types";
import {usePersonalized} from "../../hooks/usePersonalized";
import {db} from "../../hooks/db";
import {useTranslation} from "react-i18next";

type AuthContextType = {
    token: string | null;
    isAuthenticated: boolean;
    login: (token: string) => Promise<any>;
    logout: () => void;
    user?: userType;
    roles?: string[];
};

const AuthContext = createContext<AuthContextType>({
    token: null,
    isAuthenticated: false,
    // @ts-ignore
    login: () => {},
    logout: () => {},
});

const AuthProvider = ({children}: {children: ReactNode}) => {
    // region states and hooks
    const dispatch = useDispatch() as (action: any) => void;
    const [token, setToken] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<userType>();
    const [roles, setRoles] = useState<string[]>();

    const {init} = usePersonalized();
    const {i18n} = useTranslation();
    // endregion

    const login = async (token: string) => {
        localStorage.setItem("token", token);
        setToken(token);
        setIsAuthenticated(true);

        // set user data in redux
        dispatch(setUserData());

        // set user in context
        const {data} = await axios.get<{profile: userType; roles: {name: string}[]}>(`${userPrefix}/baseInfo`);

        setUser(data.profile);
        setRoles(data.roles.map((x) => x.name));
        await init(data.profile.id);
        const settings = await db.settings.get(data.profile.id);
        if (settings) {
            localStorage.setItem("i18nextLng", settings.language);
            await i18n.changeLanguage(settings.language);
        }
        // set settings in indexedDB

        // set axios token

        // set socket token
    };

    const logout = () => {
        setToken(null);
        setIsAuthenticated(false);
        dispatch(logoutUser());
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                isAuthenticated,
                user,
                roles,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export {AuthContext, AuthProvider};
