import {createContext, useContext} from "react";
import type {DGColType, filterType} from "../types";

export type DGSelectionCType = {
    checked: Set<any>;
    toggleAll: (isChecked: boolean) => void;
    toggleRow: (rowId: string, isChecked: boolean) => void;
};
export const DGSelection = createContext<DGSelectionCType>({
    checked: new Set(),
    toggleAll: () => {},
    toggleRow: () => {},
});

export function useDGSelection() {
    return useContext(DGSelection);
}

// export type DGColSelectionCType = {
//     checked: Set<any>;
//     toggleCol: (colId: string, isChecked: boolean) => void;
// };
// export const DGColSelection = createContext<DGColSelectionCType>({
//     checked: new Set(),
//     toggleCol: () => {},
// });
//
// export function useDGColSelection() {
//     return useContext(DGColSelection);
// }

const allowColIds: string[] = [];
for (let i = 0; i++; i <= 100) {
    allowColIds.push("c" + i);
}

export type DGFilteringCType = {
    sortedBy?: string;
    sortOrder: "asc" | "desc";
    filters: filterType[];
    setFilters: (filters: filterType[]) => void;
    textSearch: string;
    setTextSearch: (text: string) => void;
    onSort: (colId: string) => void;
    onChangeFilter: (filter: filterType) => void;
    onRemoveFilter: (colId: string) => void;
    count: number;
    setCount: (count: number) => void;
};
export const DGFiltering = createContext<DGFilteringCType>({
    sortOrder: "asc",
    filters: [],
    setFilters: () => {},
    textSearch: "",
    setTextSearch: () => {},
    onSort: () => {},
    onChangeFilter: () => {},
    onRemoveFilter: () => {},
    count: 0,
    setCount: () => {},
});

export function useDGFilters() {
    return useContext(DGFiltering);
}

export type DGColsCType = {
    // colsConf: ConflictType<DGColType>;
    // rowsConf: ConflictType<DGRowType>;
    hoveredCol: string;
    setHoveredCol: (colId: string) => void;
    // cols: DGColType[];
    // onAddCol: (col: Omit<DGColType, "id">) => void;
    // onChangeCol: (col: DGColType) => void;
    // onDeleteCol: (colId: string) => void;
    onResizeCol: (colId: string, width: number) => void;
    onToggleCol: (colId: string) => void;
    isCellEditable: (colId: string) => boolean;
};

// export type DGRowsCType = {
//     rowsConf: ConflictType<DGRowType>;
//     // rows: DGRowType[];
//     // onAddRow: () => void;
//     // onChangeRow: (row: DGRowType) => void;
//     // onDeleteRow: (id: string) => void;
//     // onDeleteRows: (ids: string[]) => void;
//     // onChangeCell: (rowId: string, colId: string, value: any) => void;
// };

export const DGColContext = createContext<DGColsCType>({
    // colsConf: {
    //     added: [],
    //     removed: [],
    //     modified: [],
    // },
    // cols: [],
    hoveredCol: "",
    setHoveredCol: () => {},
    // onAddCol: () => {},
    // onChangeCol: () => {},
    onToggleCol: () => {},
    // onDeleteCol: () => {},
    onResizeCol: () => {},
    isCellEditable: () => false,
    // rowsConf: {
    //     added: [],
    //     removed: [],
    //     modified: [],
    // },
});

export function useDg() {
    return useContext(DGColContext);
}

export type PermissionsType = "readonly" | "edit" | "manage" | "add" | "disabled";

export type DGSettingsType = {
    isOwner: boolean;
    id: string;
    permission: PermissionsType;
    // selectable: boolean;
    title: string;
    cols: DGColType[];
    colsWidth: Record<string, number>;
    tempColsWidth?: Record<string, number>;
    hiddenCols?: Set<string>;
    fullscreen?: boolean;
    lastModified?: number;
    activeTime?: number;
    // description: string;
    // paginated: boolean;
};

export type DGSettingContextType = {
    onChangeSetting: (key: keyof DGSettingsType, value: any) => void;
    onPublishSettings: (id: string, title: string) => void;
    onAddCol: (col: Omit<DGColType, "id">) => void;
    onChangeCol: (col: DGColType) => void;
    onDeleteCol: (colId: string) => void;
} & DGSettingsType;

export const DGDefContext = createContext<DGSettingContextType>({
    // selectable: true,
    cols: [],
    isOwner: false,
    permission: "manage",
    fullscreen: false,
    // paginated: true,
    onPublishSettings: () => {},
    onChangeSetting: () => {},
    onAddCol: () => {},
    onChangeCol: () => {},
    onDeleteCol: () => {},
    title: "",
    // description: "",
    id: "",
    activeTime: undefined,
    colsWidth: {},
    lastModified: undefined,
    tempColsWidth: {},
});

export function useDGDef() {
    return useContext(DGDefContext);
}
