import {Button, Icon} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {CodeView, LocalTimeStamp, MyTooltip} from "@ui";
import {isValid} from "date-fns";
import {Translate} from "react-localize-redux";
import {useFilters} from "./hooks/filtersContext";
import {useTranslation} from "react-i18next";
import {useMainContext} from "./hooks/mainContext";
import {ErrorButton} from "../../../utils/utilComponents";

type Props = {
    noSaveSearch?: boolean;
};

export const defaultIgnoreParams = ["keyword", "page", "size", "sord", "sidx"];

const isBool = (v) => v === "true" || typeof v === "boolean" || v === "false";
const isTimestamp = (v) => parseInt(v) > 949334400000 && parseInt(v) < 2527257600000;

export const chipSearchValue: (value: any, translationKey?: string) => any = (value, translationKey = "_") => {
    if (isBool(value)) return "";
    if ((parseInt(value) + "").length > 8 && isTimestamp(value) && isValid(parseInt(value))) return <LocalTimeStamp datetime={+value} />;
    // if (value.length > 10) return value.slice(value.length - 5, value.length) + "...";
    if (value.length > 15) return <CodeView dense>{value}</CodeView>;
    return (
        <>
            <Translate>{({translate}) => <>{translate(`${translationKey}.${value}`, null, {onMissingTranslation: () => ""})}</>}</Translate>
        </>
    );
};

const ShowFilters = ({noSaveSearch}: Props) => {
    const {t} = useTranslation()

    const {hasFilters, filters, removeFilter, clearFilters} = useFilters()
    const {saving, savingActions, sidebarActions} = useMainContext()

    return (
        <div className={"flex-grow items-center md:pl-12 md:flex justify-between"}>
            <div className={"flex-1"}>
                {Object.keys(filters).map(
                    (key) =>
                        !defaultIgnoreParams.includes(key) &&
                        !!filters[key] &&
                        filters[key] !== "" && (
                            <Chip
                                data-cy={`view-search-chip-${key}`}
                                data-value={filters[key]}
                                key={key}
                                color={"primary"}
                                className={"mr-12 mt-8 md:mt-0"}
                                label={
                                    <>
                                        <span className={filters[key] === "false" || filters[key] === false ? "line-through" : "text-gray-200"}>
                                            {t(key)}
                                            {/*{getSeachKeyLabel(key)}*/}
                                        </span>{" "}
                                        {chipSearchValue(filters[key])}
                                    </>
                                }
                                size={"small"}
                                onDelete={() => removeFilter(key)}
                                deleteIcon={
                                    <Icon className=" mr-2 -ml-1 h-20 w-20" fontSize={"small"}>
                                        close
                                    </Icon>
                                }
                            />
                        )
                )}
            </div>
            {hasFilters && (
                <div className={" mt-8 md:mt-0"}>
                    <MyTooltip title={<Translate id={"_.Clear All"} />}>
                        <ErrorButton variant={"contained"} size={"small"} startIcon={<Icon>close</Icon>} onClick={clearFilters}>
                            {t("Clear All filters")}
                        </ErrorButton>
                    </MyTooltip>
                    {!saving && !noSaveSearch && (
                        <MyTooltip title={t("save filter")}>
                            <Button
                                size={"small"}
                                className={"ml-20"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => {
                                    sidebarActions.setRight();
                                    savingActions.setRight()
                                }}
                                startIcon={<Icon>save</Icon>}>
                                <Translate id={"_.save filter"} />
                            </Button>
                        </MyTooltip>
                    )}
                </div>
            )}
        </div>
    );
};

export default ShowFilters;
