import {turnOn} from "@fuseActions";
import {useState} from "react";
import {useDispatch} from "react-redux";
import uniqid from "uniqid";
import {db} from "../db";
import {useDGDef} from "../hooks/hooks";
import type {DGRowType} from "../types";

const useIDB = () => {
    const {permission, isOwner, id} = useDGDef();
    const rowPermission = (permission === "readonly" || permission === "disabled") && !isOwner;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    function onChangeRow(row: DGRowType) {
        try {
            db.transaction("rw", db.dataGrids, db.dataGridRows, async () => {
                // if row with id doesn't exist
                if (!(await db.dataGridRows.get(row.id))) {
                    await db.dataGridRows.add({
                        ...row,
                        activeTime: +new Date(),
                        dataGridId: id,
                    });
                } else {
                    await db.dataGridRows.update(row.id, {
                        ...row,
                        activeTime: +new Date(),
                    });
                }
                await db.dataGrids.update(id, {
                    lastModified: +new Date(),
                });
            });
        } catch (error) {
            console.log(`Failed to update ${id}: ${error}`);
        }
    }

    const onDeleteRow = (rowId: string) => {
        if (rowPermission) return;
        try {
            db.transaction("rw", db.dataGrids, db.dataGridRows, async () => {
                await db.dataGridRows.delete(rowId);
                await db.dataGrids.update(id, {
                    lastModified: +new Date(),
                });
            });
        } catch (error) {
            console.log(`Failed to update ${id}: ${error}`);
        }
    };

    const onDeleteRows = (ids: string[]) => {
        if (rowPermission) return;
        setLoading(true);
        try {
            db.transaction("rw", db.dataGrids, db.dataGridRows, async () => {
                await db.dataGridRows.bulkDelete(ids).then(() => {
                    setLoading(false);
                });
                await db.dataGrids.update(id, {
                    lastModified: +new Date(),
                });
            });
        } catch (error) {
            console.log(`Failed to update ${id}: ${error}`);
        }
    };

    const onAddRow = async () => {
        if (rowPermission) return;
        if (permission === "add" && !isOwner) {
            dispatch(turnOn("newDGRecord"));
        } else {
            try {
                db.transaction("rw", db.dataGrids, db.dataGridRows, async () => {
                    await db.dataGridRows.add({
                        id: uniqid(),
                        activeTime: +new Date(),
                        dataGridId: id,
                    });
                    await db.dataGrids.update(id, {
                        lastModified: +new Date(),
                    });
                });
            } catch (error) {
                console.log(`Failed to add: ${error}`);
            }
        }
    };

    function onChangeCell(rowId: string, colId: string, value: any) {
        if (rowPermission) return;
        setLoading(true);

        try {
            db.transaction("rw", db.dataGrids, db.dataGridRows, async () => {
                if (value) {
                    await db.dataGridRows.update(rowId, {
                        [colId]: value,
                        activeTime: +new Date(),
                    });
                } else {
                    db.dataGridRows.get(rowId).then((row) => {
                        if (row) {
                            delete row[colId];
                            row.activeTime = +new Date();
                            db.dataGridRows.put(row);
                        }
                    });
                }

                await db.dataGrids.update(id, {
                    lastModified: +new Date(),
                });
            });
        } catch (error) {
            console.log(`Failed to update ${id}: ${error}`);
        }
    }

    return {
        loading,
        onChangeCell,
        onChangeRow,
        onAddRow,
        onDeleteRows,
        onDeleteRow,
    };
};

export default useIDB;
